import React from 'react';
import PropTypes from 'prop-types';

const FooterMenus = ({data}) => {
  return (
    <div className="footer__menus xl:px-36 md:px-16 px-8">
      {data.subsiteMenus && <div className={'subsite-menus grid md:grid-cols-5 grid-cols-1'}>
        {
          data.subsiteMenus.map((menu, index) => {
            return (
              <div className="md:mb-12 mb-12" key={index}>
                <a href={menu.title.link}><h3 className="mb-2 footer__main-item">{menu.title.text}</h3></a>
                {
                  menu.menuItems.map((item, itemIndex) => (
                    <div key={itemIndex}>
                      <a href={item.menuItem.link} className="footer__sub-item">{item.menuItem.label}</a>
                    </div>
                  ))
                }
              </div>
            );
          })
        }
      </div>}
      {/* {
        data.timegruppenMenu && (
          <div className="tg-menu flex justify-between md:flex-row flex-col">
            {
              data.timegruppenMenu.map((item, index) => (
                <h3 key={index} className="sm:text-center footer__main-item"><a href={item.menuItem.link.uri}>{item.menuItem.label}</a></h3>
              ))
            }
          </div>
        )
      } */}
    </div>
  )
}

FooterMenus.propTypes = {
  data: PropTypes.object.isRequired
}

export default FooterMenus;
