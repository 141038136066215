import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Hero, Timeline} from '../components';

const About = ({data}) => {

  useEffect(() => {
    // if (data && data.page && data.page.title) {
    //   document.title = data.page.title;
    // }
    if (data && data.page && data.page.seo && data.page.seo.title) {
      document.title = data.page.seo.title;
    }
    return () => {
    }
  }, [data])

  return (
    <div className="about">
      <Hero
        title={data.page.pageBlocks.hero.header}
        background={data.page.pageBlocks.hero.backgroundImage.sourceUrl}/>

      <div className="section section__history" data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
        <h2 className="text-center mb-8">{data.page.pageAbout.history.header}</h2>
        <div className={`grid grid-cols-1 gap-10 md:grid-cols-${data.page.pageAbout.history.columns.length}`}>
          {
            data.page.pageAbout.history.columns.map((column, index) => (
              <div key={index} dangerouslySetInnerHTML={{__html: column.column}}/>))
          }
        </div>
      </div>
      <div className="section section__imageColumns grid md:grid-cols-3 grid-cols-1 gap-32 md:mb-32"
        data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
        {data.page.pageAbout.imageColumns && data.page.pageAbout.imageColumns.imageLeft && data.page.pageAbout.imageColumns.imageLeft.srcSet &&
                    <img
                      className="image-left"
                      srcSet={data.page.pageAbout.imageColumns.imageLeft.srcSet}
                      alt={data.page.pageAbout.imageColumns.imageLeft.altText}/>
        }
        {data.page.pageAbout.imageColumns && data.page.pageAbout.imageColumns.imageRight && data.page.pageAbout.imageColumns.imageRight.srcSet &&
                    <img
                      className="image-right md:col-span-2"
                      srcSet={data.page.pageAbout.imageColumns.imageRight.srcSet}
                      alt={data.page.pageAbout.imageColumns.imageRight.altText}/>
        }
      </div>
      <div className="section section__timeline mb-32" data-aos="fade-up" data-aos-once="true"
        data-aos-offset="10">
        {data.page.pageAbout.timeline.header ?
          <Timeline header={data.page.pageAbout.timeline.header} years={data.page.pageAbout.timeline.years}
            backgroundColor={data.page.pageAbout.timeline.backgroundColor}/> : null}
      </div>
      <div className="section section__strategy md:mb-32" data-aos="fade-up" data-aos-once="true"
        data-aos-offset="10">
        <h2 className="mb-8">{data.page.pageAbout.strategy.header}</h2>
        <div dangerouslySetInnerHTML={{__html: data.page.pageAbout.strategy.text}}/>
      </div>

      {data.page.pageAbout.mission.image && data.page.pageAbout.mission.header && data.page.pageAbout.mission.text ?
        <div className="section section__mission grid grid-cols-1 md:grid-cols-2 md:mb-32" data-aos="fade-up"
          data-aos-once="true" data-aos-offset="10">
          {data.page.pageAbout.mission.image ? <img
            srcSet={data.page.pageAbout.mission.image.srcSet}
            alt={data.page.pageAbout.mission.image.altText}/> : null}
          <div>
            <h2 className="mb-8">{data.page.pageAbout.mission.header}</h2>
            <div dangerouslySetInnerHTML={{__html: data.page.pageAbout.mission.text}}/>
          </div>
        </div> : null}


      <div className="section section__vision flex flex-col-reverse md:grid md:grid-cols-2 md:mb-32"
        data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
        <div>
          <h2 className="mb-8">{data.page.pageAbout.vision.header}</h2>
          <div dangerouslySetInnerHTML={{__html: data.page.pageAbout.vision.text}}/>
        </div>
        {data.page.pageAbout.vision.image ? <img
          srcSet={data.page.pageAbout.vision.image.srcSet}
          alt={data.page.pageAbout.vision.image.altText}/> : null}
      </div>


      {data.page.pageAbout.values.header && data.page.pageAbout.values.subheader && data.page.pageAbout.values.columns ?
        <div className="section section__values md:mb-32" data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
          <h2 className="text-center mb-8">{data.page.pageAbout.values.header}</h2>
          <div className="mb-11" dangerouslySetInnerHTML={{__html: data.page.pageAbout.values.subheader}}/>
          <div className={`grid grid-cols-1 md:grid-cols-${data.page.pageAbout.values.columns.length} gap-8`}>
            {
              data.page.pageAbout.values.columns.map((column, index) => {
                return (
                  <div className="flex flex-col justify-start items-center" key={index}>
                    {column?.icon?.sourceUrl ?
                      <img src={column?.icon?.sourceUrl} style={{height: '45px', width: '40px'}}
                        height={45} width={40} alt={column.icon.altText}/>
                      : null
                    }
                    <h3 className="text-center my-4" style={{fontSize: '1.5rem'}}>{column.header}</h3>
                    <div dangerouslySetInnerHTML={{__html: column.text}}/>
                  </div>

                )
              })
            }
          </div>
        </div> : null}
      <div
        className="section bg-color md:py-32 py-10 lg:px-32 md:px-16 px-6"
        style={{backgroundColor: data.page.pageAbout.backgroundSection.backgroundColor}}
        data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
        <h2 className="mb-6">{data.page.pageAbout.backgroundSection.header}</h2>
        <p dangerouslySetInnerHTML={{__html: data.page.pageAbout.backgroundSection.text}}/>
      </div>
    </div>
  )
}

About.propTypes = {
  data: PropTypes.object.isRequired
}

export default About;
