import React from 'react';
import PropTypes from 'prop-types';

const HeaderAndTextSection = ({section}) => {


  return (
    <div className={
            `sections__section md:mt-32 mt-16 ${section.backgroundColor ? 'md:py-10 py-6 md:pl-24 px-6' : ''}`
    }
    data-aos="fade-up" data-aos-once="true" data-aos-offset="10"
    style={section.backgroundColor ? {backgroundColor: section.backgroundColor} : null}>
      {section.header &&
                <div className="sections__header" dangerouslySetInnerHTML={{__html: section.header}}/>}
      {section.text && (
        <div
          className={`sections__section__textColumns length-1 mt-16 ${section.centerAlignText ? 'flex flex-col items-center' : ''}`}
          dangerouslySetInnerHTML={{__html: section.text}}/>
      )}
    </div>
  );
}

HeaderAndTextSection.propTypes = {
  section: PropTypes.any,
};

export default HeaderAndTextSection;
