import React, {useEffect, useState} from 'react';
import {ApolloClient, ApolloLink, HttpLink} from '@apollo/client';
import {
  GET_CONTACT_PAGE,
  GET_EMPLOYEES,
  GET_MENUS,
  GET_NEWS_PAGE,
  GET_PAGE,
  GET_SUBSITE_PAGES,
  GET_TIMEVISION_PAGE_SECTIONS
} from '../../../gql';
import {Switch, Route} from 'react-router-dom';
import {onError} from '@apollo/client/link/error';
import {Query} from '@apollo/client/react/components/Query'
import {InMemoryCache} from 'apollo-cache-inmemory';
import PropTypes from 'prop-types';
import DefaultPage from '../default';
import {Error, AppShell, NewsletterForm} from '../../components';
import GetStarted from '../GetStarted';
import AboutTeam from '../AboutTeam';
import Contact from '../Contact';
import RevisionDefault from './Page';
import News from '../News';
import NewsPost from '../NewsPost';
import NotFound from '../404';

const devMode = process.env.NODE_ENV === 'development';

// custom error handling, only logging errors atm
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // do something with graphql error
    console.error({ graphQLErrors });
  }

  if (networkError) {
    // do something with network error
    console.error({ networkError });
  }
});

const httpLink = new HttpLink({
  uri: devMode
    ? 'https://staging.timegruppen.twentyfour.dev/revision-og-skat/graphql'
    : `https://${process.env.HOSTNAME}/revision-og-skat/graphql`
});

const link = ApolloLink.from([
  errorLink,
  httpLink,
]);

const cache = new InMemoryCache({
  introspectionQueryResultData: {
    __schema: {
      types: []
    }
  }
});

const client = new ApolloClient({
  cache,
  link,
  resolvers: {},
});

const RevisionQuery = ({children, page}) => {
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)

  let query = GET_PAGE
  const options = {stringId: page.id}

  switch (page.uri) {
    case '/':
      query = GET_TIMEVISION_PAGE_SECTIONS
      break
    case '/om-os/vores-team/':
      query = GET_EMPLOYEES
      break
    case '/kontakt/':
      query = GET_CONTACT_PAGE
      break
    case '/videnscenter/':
      query = GET_NEWS_PAGE
      options.first = 8
      break
    default:
      query = GET_PAGE
  }

  useEffect(() => {
    client.query({ query: query, variables: options })
      .then((result) => {
        setLoading(false)
        setResult(result)
      })
      .catch((error) => {
        setError(error)
      })
  }, [])

  if (loading) {
    return <AppShell hidenav={true} loading={true} />
  }

  if (error || !result) {
    return <Error />
  }

  return children(result.data)
}

RevisionQuery.propTypes = {
  page: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired
}

const RevisionRouter = ({data}) => {
  const [loading, setLoading] = useState(true) // eslint-disable-line no-unused-vars
  const [result, setResult] = useState(null) // eslint-disable-line no-unused-vars
  const [error, setError] = useState(null) // eslint-disable-line no-unused-vars

  useEffect(() => {
    client.query({query: GET_MENUS})
      .then((result) => {
        setLoading(false)
        setResult(result)
      })
      .catch((error) => {
        setError(error)
      })
  }, [])

  const renderRoutes = (page) => {
    const pathLocation = '/revision-og-skat'+page.uri;

    if (pathLocation === '/revision-og-skat/kom-igang/') {
      return <Route key={page.id} path={'/revision-og-skat/kom-igang/'}>
        <GetStarted area="revision" />
      </Route>
    }

    return <Route exact={pathLocation === '/revision-og-skat/'} key={page.id} path={pathLocation}>
      <RevisionQuery page={page}>
        {
          (data) => {
            switch (pathLocation) {
              case '/revision-og-skat/':
                return <>
                  <RevisionDefault page={data.page} />
                  { data.page.freshmail && data.page.freshmail.showNewsletterForm && <NewsletterForm data={ data.page.freshmail } /> }
                </>
              case '/revision-og-skat/om-os/vores-team/':
                return <AboutTeam data={data} />
              case '/revision-og-skat/kontakt/':
                return <Contact data={data} area={'revision'}/>
              case '/revision-og-skat/kom-igang/':
                return <GetStarted area="revision" />
              case '/revision-og-skat/videnscenter/':
                return <News page={data} subsite="TV"/>
              case '/revision-og-skat/privatlivspolitik/':
                return <div className="md:mt-14 mt-10" dangerouslySetInnerHTML={{ __html: page.content }} />
              default:
                return <DefaultPage page={data.page} />
            }
          }
        }
      </RevisionQuery>
    </Route>
  }

  return (
    <>
      <Switch>
        <Route path={'/revision-og-skat/videnscenter/:id'}><NewsPost subsite="TV" /></Route>
        {
          data.pages.nodes.map((page) => renderRoutes(page))
        }
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

RevisionRouter.propTypes = {
  data: PropTypes.object.isRequired
}

const RevisionPage = () => {
  return (
    <Query query={GET_SUBSITE_PAGES} client={client}>
      {
        ({ loading, error, data }) => {
          if (!data && loading || error) {
            return <AppShell loading={loading} hidenav={true} />
          }

          return <RevisionRouter data={data} />
        }
      }
    </Query>
  );

};

export default RevisionPage;
