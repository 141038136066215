import { gql } from '@apollo/client';

const employee = `
    nodes {
      employee {
        name
        position
        mobile
        direct
        phone
        email
        hideFromAll
      }
      featuredImage {
        node {
          altText
          sourceUrl(size: LARGE)
          srcSet(size: LARGE)
        }
      }
      tags {
        nodes {
          name
        }
      }
    }
`;

const GET_EMPLOYEES = gql`
    query getEmployees($stringId: ID!) {
        page(id: $stringId) {
            title
            seo {
              title
            }
            pageBlocks {
                hero {
                    header
                    backgroundImage {
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
            }
        }
        employees(first: 999) {
            ${employee}
        }
    }
`;

export {GET_EMPLOYEES}
