import React, {useState, useContext, useEffect} from 'react';
import {Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button} from './';
import TGlogo from '../../assets/images/TGlogo.js';
import {FORSIKRING_OG_PENSION, INVEST, REVISION, TIMELAW, TIMERIGHT} from '../constants';
import SoderbergLogo from '../../assets/images/soderbergpartners.svg';
import SiteContext from '../context/SiteContext';

const Navigation = ({data, options, subsite=null}) => {
  const { currentSiteData } = useContext(SiteContext)
  const history = useHistory();
  const [subMenuVisible, setSubMenuVisible] = useState(null);
  const [validSubMenuOpen, setValidSubMenuOpen] = useState(false);
  const [sideMenuVisible, setSideMenuVisible] = useState(false);
  const [menu, setMenu] = useState(data.nodes?.filter((item) => item.name === 'Primary')[0]) // eslint-disable-line no-unused-vars
  const [previousScrollY, setPreviousScrollY] = useState(0)
  const [sticky, setSticky] = useState(false)
  // console.log(options, 'optionsoptions')
  //const menu = data.nodes?.filter((item) => item.name === 'Primary')[0];

  useEffect(() => {
    window.onscroll = () => shouldStick()
  })

  function shouldStick() {
    const newScrollY = window.pageYOffset
    if (newScrollY > previousScrollY) {
      // we are scrolling down
      setSticky(false)
    } else {
      // we are scrolling up
      setSticky(true)
    }
    setPreviousScrollY(newScrollY <= 0 ? 0 : newScrollY)
  }

  function closeSideMenu(screen) {
    setSideMenuVisible(false)
    if (screen === 'small') {
      document.body.classList.remove('fixed-position');
    }
  }

  function getLogo() {
    return <TGlogo site={subsite} />
  }

  function getContactMenuItems(screen) {
    return (
      <div className={`flex md:py-5 py-0 flex-col ${screen === 'large' ? 'hidden lg:flex' : ''}`}>
        {
          (subsite === FORSIKRING_OG_PENSION || subsite === REVISION || subsite === INVEST || subsite === TIMELAW || subsite === TIMERIGHT) &&
            <div className="navigation__contact-menu flex lg:flex-row flex-col justify-end mb-10">
              {/*
                <Button
                  classes={
                    screen === 'small'
                      ? ['mb-4', 'text-left']
                      : ['flex-grow']
                  }
                  type="small"
                  label="LOG IND"
                  cb={() => 'log ind callback'}/>
              */}
              {
                options.headerContent.goToTimegruppenLink ?
                  (<a href="/" onClick={() => closeSideMenu()} className="lg:ml-4 timegruppen-link" dangerouslySetInnerHTML={{__html: options.headerContent.goToTimegruppenLink}}/>) :
                  (<a href="/" onClick={() => closeSideMenu()} className="lg:ml-4 timegruppen-link"> GÅ <span>TIL TIMEGRUPPEN</span></a>)
              }
              {/*<Button classes={['ml-4']} type="small" label="ENGLISH" cb={() => 'language switcher callback'} />*/}
            </div>
        }
        <div className={`flex lg:flex-row${subsite === REVISION || subsite === INVEST || subsite === TIMELAW || subsite === TIMERIGHT ? '-reverse' : ''} flex-col-reverse`}>
          <Button
            classes={
              screen === 'small'
                ? ['mb-8', 'small', 'whitespace-nowrap', 'get-started-button']
                : ['small', 'whitespace-nowrap']
            }
            type="block"
            label={options.headerContent.getStartedButton || 'KOM I GANG'}
            cb={() => {
              history.push(`${currentSiteData.baseUrl}kom-igang`)
              // history.push(`${path}kom-igang`)
              closeSideMenu(screen)
            }} />
          {
            subsite === FORSIKRING_OG_PENSION && <img className="lg:ml-10" width="139px" height="39px" src={SoderbergLogo} alt="Söderberg & Partners"/>
          }
        </div>
      </div>
    );
  }

  function getMenuItems(screen) {
    return (
      <div className={`flex lg:flex-row flex-col ${subsite ? 'pt-20' : 'md:py-5 py-20'} pb-0`}>
        <div className="lg:block hidden icon xl:mr-20 lg:mr-10 md:mr-6">
          <Link to={currentSiteData.baseUrl}>{getLogo()}</Link>
        </div>
        {menu?.menuItems?.nodes?.map((item, index) => {
          if (item.parentId) return;
          const menuItemProps = screen === 'small' ? {} : {href: item.path};
          const hasSubItems = item.childItems.nodes.length > 0;
          if (!hasSubItems) {
            menuItemProps.href = item.path;
          }
          return (
            <div className={`flex flex-col lg:flex-row navigation__item ${index !== menu.menuItems.nodes.length - 1 ? 'lg:mr-6 md:mr-4 mr-0' : ''}`}
              key={index}>
              {
                screen === 'small' ?
                  <div className="flex items-center">
                    <Link
                      className={`${hasSubItems ? 'w-1/2' : ''}`}
                      to={ item.path }
                      onClick={() => closeSideMenu(screen)}
                    >
                      { item.label }
                    </Link>
                    {hasSubItems && <div
                      className="w-1/2 relative flex justify-end cursor-pointer"
                      onClick={() => {
                        setSubMenuVisible(subMenuVisible === index ? null : index)
                      }}
                    >
                      <div
                        className={`caret ease-out transition-all duration-300${subMenuVisible === index ? ' active' : ''}`}
                      />
                    </div>}
                  </div>
                  :
                  <Link
                    {...menuItemProps}
                    to={item.path}
                    onMouseEnter={() => {
                      if (screen === 'small') return
                      setValidSubMenuOpen(true)
                      setSubMenuVisible(index)
                    }}
                    onMouseLeave={() => {
                      if (screen === 'small') return
                      setSubMenuVisible(null)
                    }}
                    onClick={() => {
                      if (subMenuVisible === index) {
                        history.push(item.path)
                        setSubMenuVisible(null)
                      }
                      else setSubMenuVisible(index);
                    }}
                    className="flex justify-between items-center"
                  >{item.label} {
                      hasSubItems
                  && <div
                    className={`caret ease-out transition-all duration-300${subMenuVisible === index ? ' active' : ''}`}/>
                    }</Link>
              }
              {
                hasSubItems && (
                  <div
                    onMouseEnter={() => {
                      if (screen === 'small') return
                      if (validSubMenuOpen) setSubMenuVisible(index)
                    }}
                    onMouseLeave={() => {
                      if (screen === 'small') return
                      setValidSubMenuOpen(false)
                      setSubMenuVisible(null)
                    }}
                    className={[
                      'flex',
                      'flex-col',
                      'ease-out',
                      'transition-all',
                      'duration-300',
                      'navigation__subitems',
                      `${subMenuVisible === index ? 'visible' : ''}`,
                      'mt-0',
                      `${subsite === FORSIKRING_OG_PENSION ? 'md:mt-14' : subsite === INVEST ? 'md:mt-11' : 'md:mt-11'}`
                    ].join(' ')}>
                    {item.childItems.nodes.map((subItem, subIndex) => {
                      return (
                        <Link key={subIndex} to={subItem.path} onClick={() => closeSideMenu(screen)}>{subItem.label}</Link>
                      )
                    })}
                  </div>
                )
              }
            </div>
          )
        })}
      </div>
    );
  }

  return (
    <div className={`navigation flex justify-between items-center 2xl:px-36 xl:pl-36 md:pl-16 pr-2 pl-8 ${sticky ? 'sticky' : 'relative z-50'}`} id="header">
      <div className="flex lg:hidden justify-between py-4 w-full">
        <Link className="navigation__icon" to={ currentSiteData.baseUrl } onClick={() => closeSideMenu()}><TGlogo site={subsite} /></Link>
        {/* Mobile menu icon */}
        <div
          className={`flex lg:hidden hamburger flex-col justify-between self-center cursor-pointer${sideMenuVisible ? ' visible' : ''}`}
          onClick={() => {
            // prevents scrolling the body while menu is open
            document.body.classList.toggle('fixed-position')
            setSideMenuVisible(!sideMenuVisible)
          }}>
          <div className="hamburger__line ease-in-out transition-all duration-300" />
          <div className="hamburger__line ease-in-out transition-all duration-300" />
        </div>
        {/* Mobile menu aside */}
        <aside
          className={[
            'transform',
            'bg-white',
            'h-full',
            'fixed',
            'block',
            'lg:hidden',
            'w-screen',
            'md:w-6/12',
            'aside-menu',
            'ease-in-out',
            'transition-all',
            'duration-300',
            'z-30',
            sideMenuVisible ? 'visible' : ''
          ].join(' ')}>
          {getMenuItems('small')}
          {getContactMenuItems('small')}
        </aside>
      </div>
      {/* Desktop menu bar */}
      <div className="hidden md:hidden lg:block">{getMenuItems()}</div>
      {getContactMenuItems('large')}
    </div>
  );
}

Navigation.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  subsite: PropTypes.string
};

export default Navigation;
