import React from 'react';
import PropTypes from 'prop-types';

const ReadMore = ({label, link, external = false, color = null}) => {
  const extraStyle = {}
  if (color) extraStyle.color = color
  const stroke = {stroke: color ? color : '#293769'}
  //const fill = {fill: color ? color : '#293769'}

  if (external) {
    return (
      <a className="read-more" href={link} target="_blank" rel="noreferrer" style={extraStyle}>
        <span>{label}</span>
        <svg width="24" height="2rem" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" {...stroke}/></svg>
      </a>
    )
  }

  return (
    (
      <a className="read-more" href={link} style={extraStyle}>
        <span>{label}</span>
        <svg width="24" height="2rem" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" {...stroke}/></svg>
      </a>
    )
  )
}

ReadMore.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  external: PropTypes.bool,
  color: PropTypes.string,
}


export default ReadMore
