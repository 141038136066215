import React, { useEffect, Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

function ScrollToTop({ history, children }) {
  const [ locationKeys, setLocationKeys ] = useState([])
  useEffect(() => {
    const unlisten = history.listen((location) => {
      // https://www.codegrepper.com/code-examples/javascript/how+to+detect+browser+back+button+click+in+react+js
      if (history.action === 'PUSH') {
        window.scrollTo(0, 0);
        setLocationKeys([ location.key ])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys) // eslint-disable-line no-unused-vars

          // Handle forward event

        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])

          // Handle back event

        }
      }
    });
    return () => {
      unlisten();
    }
  }, []);

  return <Fragment>{children}</Fragment>;
}

ScrollToTop.propTypes = {
  history: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired
}

export default withRouter(ScrollToTop);
