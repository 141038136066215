import React from 'react';
import PropTypes from 'prop-types';
import {Footer, TopBar} from './';

const AppContainer = ({ children }) => {
  return (
    <>
      <TopBar />
      <div className="xl:px-36 md:px-16 px-8 lg:pb-32 md:pb-20 pb-16 w-screen" id="app-content">{children}</div>
      <Footer />
    </>
  )
}

AppContainer.propTypes = {
  children: PropTypes.any,
  themeOptions: PropTypes.any
}

export default AppContainer
