import React, {useState, useEffect} from 'react';
import {withApollo} from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import Input from './Input';
import Button from './Button';
import { GET_THEMEOPTIONS } from '../../gql';
import { useSiteClient } from '../hooks/UseSiteQuery';

const Form = ({
  formFields,
  callbackLabel,
  callback,
  status = null,
  extraClasses = '',
  gridColumns = '1',
  rowGap = '4',
  columnGap = '4',
  sendingStatus,
  defaultState
}) => {
  const [fields, setFields] = useState(formFields)
  const [gdprLabel, setGdprLabel] = useState('')
  const client = useSiteClient()
  const [data, setQueryObject] = useState(undefined)
  const [loading, setLoading] = useState(true) // eslint-disable-line no-unused-vars


  useEffect(() => {
    if (client && !data) {
      client.query({ query: GET_THEMEOPTIONS, client: client })
        .then((result) => {
          setLoading(false)
          setGdprLabel(result.data.themeOptions.gdprFormInfo.label)
          setQueryObject(result.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [client])


  function handleSubmit(event) {
    event.preventDefault()
    callback(fields)

    // set all fields to default state
    setFields(defaultState)
  }

  return (
    <form onSubmit={handleSubmit} className={['form', extraClasses].join(' ')} autoComplete={'false'}>
      <div className={`grid md:grid-cols-${gridColumns} grid-cols-1 md:gap-y-${rowGap} gap-y-8 gap-x-${columnGap}`}>
        {
          fields?.map((field, index) => {
            // TODO make required editable in CMS
            return (
              <Input
                required={true}
                key={index}
                name={field.name}
                element={field.element}
                type={field.type}
                value={field.value}
                placeholder={field.placeholder}
                label={field.label}
                callback={(inputResponse) => {
                  const temp = [...fields]
                  temp[index].value = inputResponse
                  setFields(temp)
                }}
              />
            )
          })
        }
        {<div className='wysiwyg' dangerouslySetInnerHTML={{ __html: gdprLabel }} />}
      </div>
      {fields ? <Button disabled={sendingStatus} classes={['mt-24 form__button']} label={callbackLabel} isSubmit={true} cb={() => {}} /> : null }
      {
        status && (
          <p>{status.type}: {status.message}</p>
        )
      }
    </form>
  )
}

Form.propTypes = {
  formFields: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired,
  callbackLabel: PropTypes.string.isRequired,
  status: PropTypes.object,
  extraClasses: PropTypes.string,
  gridColumns: PropTypes.string,
  rowGap: PropTypes.string,
  columnGap: PropTypes.string,
  client: PropTypes.any,
  sendingStatus: PropTypes.bool,
  defaultState: PropTypes.array
}

export default withApollo(Form);
