import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import Button from './Button';
import SiteContext from '../context/SiteContext';

const FooterCTA = ({cta}) => {
  const history = useHistory();
  const { currentSiteData } = useContext(SiteContext)
  const attributes = {}
  const classes = ['footer__cta', 'flex', 'flex-col', 'items-center', 'md:py-32', 'py-10']

  if (!cta.backgroundImage && !cta.title && (cta.button && !cta.button.label)) {
    return null
  }

  if (cta.backgroundColor) {
    attributes.style = {
      backgroundColor: cta.backgroundColor
    }
  }
  if (cta.backgroundImage && cta.backgroundImage.sourceUrl) {
    classes.push('bg-image')
    if (attributes.style) {
      attributes.style.backgroundImage = `url('${cta.backgroundImage.sourceUrl}')`
    } else {
      attributes.style = {
        backgroundImage: `url(${cta.backgroundImage.sourceUrl})`
      }
    }
  }

  return (
    <div className="footer__cta flex flex-col items-center md:py-32 py-10 xl:px-36 md:px-16 px-20" {...attributes}>
      <h2 className="md:mb-12 mb-7 text-center">{cta.title}</h2>
      {cta.button && cta.button.label && <Button label={cta.button.label} cb={() => history.push(currentSiteData.baseUrl + cta.button.link.slug)} />}
    </div>
  )
}

FooterCTA.propTypes = {
  cta: PropTypes.object.isRequired
}

export default FooterCTA;
