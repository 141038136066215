import React from 'react';
import PropTypes from 'prop-types';

const InvestmentFunds = ({funds}) => {
  //console.log('InvestmentFunds', funds)
  return (
    <div className="sm:overflow-x-scroll grid lg:grid-cols-2 gap-8 md:mt-32 mt-20 investment-funds">
      {
        funds && funds.map((fund, index) => {
          return (
            <div
              key={index}
              style={{ backgroundImage: `url(${fund.backgroundImage.sourceUrl})` }}
              className="investment-funds__fund bg-no-repeat bg-cover sm:mr-4">
              <div className="fund-text-container py-10 md:py-12 lg:px-16 md:px-12 px-6">
                <h2 className="fund-header mb-6">{fund.header}</h2>
                <div className="fund-text" dangerouslySetInnerHTML={{ __html: fund.text }} />
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

InvestmentFunds.propTypes = {
  funds: PropTypes.array
}

export default InvestmentFunds
