import React from 'react';
import PropTypes from 'prop-types';

const Hero = ({title, background, normalMargin = false, extraBackgrounds = []}) => {
  const extraStyles = {};
  const classes = ['md:pb-28 pb-3.5', 'hero' , 'fade-in'];
  if (!normalMargin) {
    classes.push('xl:-ml-36 md:-ml-16 -ml-8')
    classes.push('xl:pl-36 md:pl-20 pl-8')
  }
  if (background) {
    extraStyles.backgroundImage = `url(${background})`;
    classes.push('with-background');
  }

  return (
    <div
      className={classes.join(' ')}
      style={extraStyles}>
      {
        title &&
        <div className='title' dangerouslySetInnerHTML={{ __html: title }} />
      }
      {
        extraBackgrounds.length ?
          <div 
            className='hero-extra-bg'>
            {
              extraBackgrounds.map((extraBackground, key) => (
                extraBackground.srcSet ? <img id={`hero-extra-bg-id-${key}`} className='inactive' srcSet={extraBackground.srcSet} key={key} /> : null
              ))
            }
          </div> : null
      }
    </div>
  );
}

Hero.propTypes = {
  title: PropTypes.string,
  background: PropTypes.string,
  normalMargin: PropTypes.bool,
  extraBackgrounds: PropTypes.array
};

export default Hero;
