import React, {useEffect, useState} from 'react';
import {ApolloClient, ApolloLink, HttpLink} from '@apollo/client';
import {
  GET_CONTACT_PAGE,
  GET_EMPLOYEES,
  GET_F_P_INSURANCES_PAGE,
  GET_F_P_PENSIONS_PAGE,
  GET_MENUS, GET_NEWS_PAGE,
  GET_PAGE,
  GET_SUBSITE_PAGES
} from '../../../gql';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import {onError} from '@apollo/client/link/error';
import {Query} from '@apollo/client/react/components/Query'
import {InMemoryCache} from 'apollo-cache-inmemory';
import DefaultPage from '../default';
import {LoadingIndicator, Error, InsuranceSections, AppShell} from '../../components';
import GetStarted from '../GetStarted';
import PensionSolutionsGrid from '../../components/PensionSolutionsGrid';
import AboutTeam from '../AboutTeam';
import Contact from '../Contact';
import News from '../News';
import NewsPost from '../NewsPost';
import NotFound from '../404';

const devMode = process.env.NODE_ENV === 'development';

// custom error handling, only logging errors atm
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // do something with graphql error
    console.error({ graphQLErrors });
  }

  if (networkError) {
    // do something with network error
    console.error({ networkError });
  }
});

const httpLink = new HttpLink({
  uri: devMode
    ? 'https://staging.timegruppen.twentyfour.dev/forsikring-og-pension/graphql'
    : `https://${process.env.HOSTNAME}/forsikring-og-pension/graphql`
});

const link = ApolloLink.from([
  errorLink,
  httpLink,
]);

const cache = new InMemoryCache({
  introspectionQueryResultData: {
    __schema: {
      types: []
    }
  }
});

const client = new ApolloClient({
  cache,
  link,
  resolvers: {},
});

const FPQuery = ({children, page}) => {
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)

  let query = GET_PAGE
  const options = {stringId: page.id}

  switch (page.uri) {
    case '/vi-tilbyder/erhvervsforsikring/':
      query = GET_F_P_INSURANCES_PAGE
      break
    case '/vi-tilbyder/pension/':
      query = GET_F_P_PENSIONS_PAGE
      break
    case '/om-os/vores-team/':
      query = GET_EMPLOYEES
      break
    case '/kontakt/':
      query = GET_CONTACT_PAGE
      break
    case '/videnscenter/':
      query = GET_NEWS_PAGE
      options.first = 3
      break
    default:
      query = GET_PAGE
  }

  useEffect(() => {
    client.query({ query: query, variables: options })
      .then((result) => {
        setLoading(false)
        setResult(result)
      })
      .catch((error) => {
        setError(error)
      })
  }, [])

  if (loading || !result) {
    return <div>
      <div className="full-page-spinner"><LoadingIndicator /></div>
      <div style={{ position: 'relative', height: '100vh', width: '100vw', display: 'block'}}></div>
    </div>
  } else if (error) {
    return <Error />
  }

  return children(result.data)
}

FPQuery.propTypes = {
  page: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired
}

const FPRouter = ({data}) => {
  const [loading, setLoading] = useState(true) // eslint-disable-line no-unused-vars
  const [result, setResult] = useState(null) // eslint-disable-line no-unused-vars
  const [error, setError] = useState(null) // eslint-disable-line no-unused-vars
  let { path } = useRouteMatch();

  useEffect(() => {
    client.query({query: GET_MENUS})
      .then((result) => {
        setLoading(false)
        setResult(result)
      })
      .catch((error) => {
        setError(error)
      })
  }, [])

  const renderRoutes = (page) => {
    const pathLocation = '/forsikring-og-pension'+page.uri;
    switch (pathLocation) {
      case '/forsikring-og-pension/':
        return <Route exact key={page.id} path={path+page.uri}><FPQuery page={page}>{(data) =>
          <>
            <DefaultPage page={data.page} />
          </>
        }</FPQuery></Route>
      case '/forsikring-og-pension/videnscenter/':
        return <Route key={page.id} path={'/forsikring-og-pension/videnscenter/'}>
          <FPQuery page={page}>{(data) =>
            <News page={data} subsite="FP"/>
          }</FPQuery>
        </Route>
      case '/forsikring-og-pension/vi-tilbyder/':
        return <Route exact key={page.id} path={'/forsikring-og-pension/vi-tilbyder/'}><FPQuery page={page}>{(data) =>
          <DefaultPage page={data.page}/>
        }</FPQuery>
        </Route>
      case '/forsikring-og-pension/vi-tilbyder/vaerdiplanlaegning/':
        return <Route key={page.id} path={'/forsikring-og-pension/vi-tilbyder/vaerdiplanlaegning/'}><FPQuery page={page}>{(data) =>
          <DefaultPage page={data.page}/>
        }</FPQuery>
        </Route>
      case '/forsikring-og-pension/vi-tilbyder/erhvervsforsikring/':
        return <Route key={page.id} path={'/forsikring-og-pension/vi-tilbyder/erhvervsforsikring/'}><FPQuery page={page}>{(data) =>
          <><DefaultPage page={data.page}/><InsuranceSections data={data.page.pageBusinessInsurance} /></>
        }</FPQuery>
        </Route>
      case '/forsikring-og-pension/vi-tilbyder/pension/':
        return <Route key={page.id} path={'/forsikring-og-pension/vi-tilbyder/pension/'}><FPQuery page={page}>{(data) =>
          <DefaultPage page={data.page} plugincomponent={{position: 4, component: <PensionSolutionsGrid data={data.page.pagePension} />}}/>
        }</FPQuery>
        </Route>
      case '/forsikring-og-pension/om-os/':
        return <Route key={page.id} path={'/forsikring-og-pension/om-os/'}><FPQuery page={page}>{(data) =>
          <DefaultPage page={data.page}/>
        }</FPQuery>
        </Route>
      case '/forsikring-og-pension/om-os/vores-team/':
        return <Route key={page.id} path={'/forsikring-og-pension/om-os/vores-team/'}>
          <FPQuery page={page}>{(data) => <AboutTeam data={data}/>}</FPQuery>
        </Route>
      case '/forsikring-og-pension/kom-igang/':
        return <Route key={page.id} path={'/forsikring-og-pension/kom-igang/'}>
          <GetStarted area="forsikring-og-pension" />
        </Route>
      case '/forsikring-og-pension/kontakt/':
        return <Route key={page.id} path={'/forsikring-og-pension/kontakt/'}>
          <FPQuery page={page}>{(data) => <Contact data={data} area={'forsikring-og-pension'}/>}</FPQuery>
        </Route>
      case '/forsikring-og-pension/privatlivspolitik/':
        return <Route key={page.id} path={'/forsikring-og-pension/privatlivspolitik/'}>
          <div className="md:mt-14 mt-10" dangerouslySetInnerHTML={{ __html: page.content }} />
        </Route>
      default:
        return <Route key={page.id} path={path+page.slug}><FPQuery page={page}>{(data) =>
          <DefaultPage page={data.page}/>
        }</FPQuery>
        </Route>
    }
  }

  return (
    <>
      <Switch>
        <Route path={'/forsikring-og-pension/videnscenter/:id'}><NewsPost subsite="FP" /></Route>
        {
          data.pages.nodes.map((page) => renderRoutes(page))
        }
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

FPRouter.propTypes = {
  data: PropTypes.object.isRequired
}

const FPPage = () => {
  return (
    <Query query={GET_SUBSITE_PAGES} client={client}>
      {
        ({ loading, error, data }) => {
          if (!data && loading || error) {
            return <AppShell loading={loading} hidenav={true} />
          }

          return <FPRouter data={data} />
        }
      }
    </Query>
  );

};

export default FPPage;
