import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Form, PageSections} from '../components/';
import {useMutation} from '@apollo/client';
import {SEND_EMAIL} from '../../gql';

const Contact = ({data, area}) => {
  const [emailStatus, setEmailStatus] = useState(null);
  const [sendingStatus, setSendingStatus] = useState(false)
  let defaultState = [
    {name: 'email', element: 'input', type: 'email', value: '', placeholder: 'Email'},
    {name: 'telephone', element: 'input', type: 'tel', value: '', placeholder: 'Telefonnummer'},
    {name: 'other', element: 'textarea', value: '', placeholder: 'Ellers andet?'},
  ]
  let [formFields, setFormFields] = useState([
    {name: 'email', element: 'input', type: 'email', value: '', placeholder: 'Email'},
    {name: 'telephone', element: 'input', type: 'tel', value: '', placeholder: 'Telefonnummer'},
    {name: 'other', element: 'textarea', value: '', placeholder: 'Ellers andet?'},
  ])
  const [sendEmail] = useMutation(SEND_EMAIL);

  useEffect(() => {
    if (data && data.page && data.page.seo && data.page.seo.title ) {
      document.title = data.page.seo.title;
    }
    return () => {}
  }, [data])

  function getToEmail(area) {

    let email;

    switch (area) {
      case 'timeinvest':
        email = 'timeinvest@time.dk,cff@time.dk,cba@time.dk'
        break;
      case 'revision':
        email = 'timevision@time.dk,cff@time.dk'
        break;
      case 'forsikring-og-pension':
        email = 'time@soderbergpartners.dk,cff@time.dk'
        break;
      case 'timelaw':
        email = 'timelaw@timelaw.dk,hgc@time.dk,cff@time.dk'
        break;
      case 'GDPR':
        email = 'timegruppen@time.dk,cff@time.dk'
        break;
      default:
        email = 'timegruppen@time.dk,cff@time.dk';
    }
    return email;
  }


  function submitForm() {
    const toEmail = getToEmail(area)
    if (sendingStatus) return

    const body = `
            Email: ${formFields[0].value}
            
            Telephone: ${formFields[1].value}
            
            Other: ${formFields[2].value}
            `;
    setSendingStatus(true)

    sendEmail({variables: {to: toEmail, subject: `${window.location.href} Contact Form`, body}})
      .then((res) => {
        if (res.data.sendEmail.sent) {
          setEmailStatus({type: 'success', message: 'Besked afsendt'})
        } else {
          setEmailStatus({type: 'error', message: res.data.sendEmail.message})
        }
      })
      .catch((err) => {
        setEmailStatus({type: 'error', message: err.message})
      })
      .finally(() => {
        // set form fields to default state
        setFormFields([])
        setSendingStatus(false)
      })
  }

  return (
    <>
      <div className="grid md:grid-cols-2 grid-cols-1 md:pt-20 pt-10">
        <div className="mt-10 md:mt-0 form-image" style={{ backgroundImage: `url(${data.page.pageContact.formImage?.sourceUrl})` }} />
        {/*<img srcSet={data.page.pageContact.formImage.srcSet} alt={data.page.pageContact.formImage.altText} />*/}
        <div className="md:pl-24 md:mt-0 mt-10">
          <h2 className="md:mb-2 mb-6">{data.page.pageContact.formHeader}</h2>
          <p className="mb-10" dangerouslySetInnerHTML={{ __html: data.page.pageContact.formSubheader }}/>
          <Form status={emailStatus} defaultState={defaultState} formFields={formFields} sendingStatus={sendingStatus} callbackLabel={data.page.pageContact.formSubmitButton} callback={(response) => {
            setFormFields(response)
            submitForm(area)
          }} />
        </div>
      </div>
      {data.page.pageContact.addresses && data.page.pageContact.addresses.length &&
      <div className={
        `grid md:grid-cols-${data.page.pageContact.addresses.length > 3 ? 2 : 3} 
        grid-cols-1 gap-7 addresses-section ${data.page.pageContact.addresses.length > 3 ? 'max-w-4xl mx-auto' : ''}`}>
        {
          data.page.pageContact.addresses.map((address, index) => (
            <div key={index} className="flex flex-col address">
              <img srcSet={address.mapImage?.srcSet} alt={address.mapImage?.altText}/>
              <div className="lg:py-9 py-8 lg:px-8 px-7">
                <h3 className="mb-6">{address.header}</h3>
                <p className="mb-2" dangerouslySetInnerHTML={{ __html: address.address }}/>
                <a className="mb-2" href={`tel:${address.phone}`}>{address.phone}</a>
                <a href={`mailto:${address.email}`}>{address.email}</a>
              </div>
            </div>
          ))
        }
      </div>}
      { data.page.pageContact.hidePressContactSection ? null :
        <div className="flex flex-col items-center justify-center md:mt-32 press-section py-10 md:px-64 px-14"
          style={{ backgroundImage: `url(${data.page.pageContact.pressContactSection.watermark?.sourceUrl})` }}>
          <h4 className="text-white text-center mb-6">{data.page.pageContact.pressContactSection.header}</h4>
          <p className="text-center mb-6" dangerouslySetInnerHTML={{ __html: data.page.pageContact.pressContactSection.subheader }} />
          <p className="bold text-center mb-2" dangerouslySetInnerHTML={{ __html: data.page.pageContact.pressContactSection.contactName }} />
          <p className="text-center mb-6">{data.page.pageContact.pressContactSection.contactTitle}</p>
          <a className="text-center mb-2" href={`tel:${data.page.pageContact.pressContactSection.contactPhone}`}>Telefon: {data.page.pageContact.pressContactSection.contactPhone}</a>
          <a className="text-center mb-6" href={`tel:${data.page.pageContact.pressContactSection.contactPhone}`}>E-mail: {data.page.pageContact.pressContactSection.contactEmail}</a>
          <p className="text-center" dangerouslySetInnerHTML={{ __html: data.page.pageContact.pressContactSection.contactFooter }} />
        </div>
      }
      { data.page.pageBlocks && data.page.pageBlocks.sections && <PageSections sections={data.page.pageBlocks.sections} />}
    </>
  )
}

Contact.propTypes = {
  data: PropTypes.object.isRequired,
  area: PropTypes.string,
}

export default Contact;
