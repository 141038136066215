import React from 'react';
import PropTypes from 'prop-types';
import VimeoVideoPlayIcon from '../../../assets/images/VimeoVideoPlayIcon.svg';


const VimeoVideo = ({vimeoVideoId, vimeoVideoPoster}) => {
  const [isPlaying, setIsPlaying] = React.useState(false);

  return (

    <div className="videotext__video-wrapper">
      {isPlaying ? (
        <iframe
          className="videotext__video-frame"
          src={`https://player.vimeo.com/video/${vimeoVideoId}?color=e45334&autopause=0&autoplay=1&loop=0&muted=0&title=1&portrait=1&byline=0#t=`}
          frameBorder="0"
          allowFullScreen="1"
          allow="autoplay;encrypted-media;"
        />
      ) : (
        <figure className="videotext__video-poster" style={{ backgroundImage: `url(${vimeoVideoPoster ? vimeoVideoPoster.sourceUrl : 'none'})` }}>
          <img className="videotext__play-icon" src={VimeoVideoPlayIcon}  alt="Play" onClick={() => setIsPlaying(true)} />
        </figure>
      )}
    </div>

  )
}

VimeoVideo.propTypes = {
  vimeoVideoId: PropTypes.string,
  vimeoVideoPoster: PropTypes.exact({
    sourceUrl: PropTypes.string,
    altText: PropTypes.string,
    __typename: PropTypes.string,
  })
}

export default VimeoVideo
