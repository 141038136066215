import React from 'react';
import PropTypes from 'prop-types';
import {ReadMore} from '../index';

const ImageTextColumns = ({section}) => {
  return (
    <div className={
            `sections__section__image-and-text 
                   md:mt-32 mt-16 flex flex-col 
                   ${section.imageAlignment === 'right' ? 'md:flex-row-reverse md:pl-20' : 'md:flex-row md:pr-20'}`
    } data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
      <img
        className="mb-6 md:mb-0 object-cover cover-image"
        srcSet={section.image?.srcSet} alt={section.image?.altText} />
      <div>
        {section.header && <div className={`header header--${section.imageAlignment}`} dangerouslySetInnerHTML={{ __html: section.header }} />}
        <div
          className={`text text--${section.imageAlignment}`}
          dangerouslySetInnerHTML={{ __html: section.text }} />
        {
          section.readMore && section.readMore.link &&
                    <div className={`text--${section.imageAlignment}`}>
                      <ReadMore label={section.readMore.label} link={section.readMore.link.link}/>
                    </div>
        }
      </div>
    </div>
  );
}

ImageTextColumns.propTypes = {
  section: PropTypes.any,
};

export default ImageTextColumns;


