import React from 'react';
import PropTypes from 'prop-types';

const PensionSolutionsGrid = ({data}) => {
  //console.log('PensionSolutionsGrid', data)
  return (
    <div data-aos="fade-up" data-aos-once="true" data-aos-offset="10"
      className="md:mt-32 mt-16 grid md:grid-cols-3 grid-cols-1 pension-solutions-grid md:gap-8 gap-4">
      {
        data.columns.map((column, index) => {
          const bgColor = column.overlayColor.substring(0, 7) + 'CC' + column.overlayColor.substring(7)
          const minHeight = '491px'
          return (
            <div className="pension-solutions-grid__item"
              key={index} style={{backgroundImage: `url(${column.backgroundImage.sourceUrl})`, minHeight}}>
              <div className="overlay" style={{backgroundColor: bgColor}} />
              <div className="content md:px-6 px-4 md:py-12 py-6" dangerouslySetInnerHTML={{ __html: column.content }} />
            </div>
          )
        })
      }
    </div>
  )
}

PensionSolutionsGrid.propTypes = {
  data: PropTypes.object.isRequired
}

export default PensionSolutionsGrid
