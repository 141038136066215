import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TimeGlass from '../../assets/images/glass';

const OptionsSelector = ({data, type}) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)
  //console.log(data)

  const options = () => {
    return (
      <div className={`grid ${data.hourglass ? 'hourglass grid-cols-2' : 'md:grid-cols-3 grid-cols-1'} md:gap-20 gap-4`}
        data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
        <div>
          {data.options.map((item, index) => (
            <h3
              key={index}
              className={`options-selector__option ${data.numbered && 'options-selector__option--numbered'} cursor-pointer pl-4 ${selectedOptionIndex === index ? 'active': ''}`}
              onClick={() => setSelectedOptionIndex(index)}
            >
              {data.numbered && <span className="index">{index+1}.</span>}{item.option}
            </h3>
          ))}
        </div>
        <div className={`options-selector__options ${data.image ? 'bg-image' : 'flex flex-col md:justify-center'}`}
          style={data.image ? {backgroundImage: `url(${data.image.sourceUrl})`} : null}>
          {
            data.options[selectedOptionIndex].bullets && (
              <div dangerouslySetInnerHTML={{ __html: data.options[selectedOptionIndex].bullets }}/>
            )
          }
          {
            data.options[selectedOptionIndex].result && (
              <div className="option-result"
                style={{ backgroundColor: data.options[selectedOptionIndex].backgroundColor }}
                dangerouslySetInnerHTML={{ __html: data.options[selectedOptionIndex].result }}/>
            )
          }
        </div>
      </div>
    )
  }

  return (
    <div
      data-aos="fade-up" data-aos-once="true" data-aos-offset="10"
      className={`section options-selector grid ${type === 'hourglass' ? 'lg:grid-cols-3' : 'md:grid-cols-2'} grid-cols-1 md:mt-32 mt-16 ${type === 'hourglass' ? 'md:px-20 px-0' : ''}`}>
      <div className={type === 'hourglass' ? 'col-span-2' : 'col-span-2'}>
        <div className="mb-4" dangerouslySetInnerHTML={{ __html: data.header }}/>
        <p className="md:mb-11 mb-8">{data.subHeader}</p>
        {options()}
      </div>
      {
        type === 'hourglass' &&
          <div className="flex justify-end flex-col items-center sm:mt-10">
            <div className={`options-selector__image ${type === 'hourglass' ? '' : 'flex'}`} style={{ paddingTop: (type === 'hourglass' ? '4rem' : '0') }}>
              <TimeGlass/>
            </div>
          </div>
      }
    </div>
  )
}

OptionsSelector.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string
}

export default OptionsSelector
