import React from 'react';
import PropTypes from 'prop-types';

const ImageSection = ({section}) => {
  return (
    <div className="sections__section md:mt-32 mt-16 flex flex-col justify-center items-center overflow-hidden" data-aos="fade-up" data-aos-once="true" data-aos-offset="10" style={section.fullWidth ? {maxHeight: '528px'} : null}>
      <img className={`${!section.fullWidth ? 'md:w-1/2' : 'object-cover'} w-full`} srcSet={section.image.srcSet} alt={section.image.altText} style={section.maxWidth ? {maxWidth: `${section.maxWidth}px`} : null} />
    </div>
  );
}

ImageSection.propTypes = {
  section: PropTypes.any,
};

export default ImageSection;
