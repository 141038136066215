import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {Hero} from '../../components'
import ReadMore from '../../components/ReadMore';

const TimeInvestAbout = ({page}) => {
  //console.log('Timeinvest About page', page)
  const {pageAbout} = page

  useEffect(() => {
    if (page && page.seo && page.seo.title ) {
      document.title = page.seo.title;
    }
    return () => {}
  }, [page])

  function getSection(section, type, key) {
    switch (type) {
      case 'HeaderAndText':
        return (
          <div className={`md:mt-32 mt-16 section section__${type}`} key={key} data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
            <div className="header" dangerouslySetInnerHTML={{ __html: section.header }} /><div className="text" dangerouslySetInnerHTML={{ __html: section.text }} />
            {
              section.readMoreUrl.label && section.readMoreUrl.linkUrl && (
                <div className="md:mt-6 mt-4"><ReadMore label={section.readMoreUrl.label} link={section.readMoreUrl.linkUrl}/></div>
              )
            }
          </div>
        )
      case 'ImageColumns':
        return (
          <div className={`md:mt-32 mt-16 section section__${type} grid md:grid-cols-5 grid-cols-1 gap-24`} key={key}
            data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
            <img
              className="image-left md:col-span-2 object-cover"
              srcSet={section.leftImage?.srcSet}
              alt={section.leftImage?.altText} />
            <img
              className="image-right md:col-span-3 object-cover"
              srcSet={section.rightImage?.srcSet}
              alt={section.rightImage?.altText} />
          </div>
        )
      case 'ImageAndQuote':
        return (
          <div
            className={
              `md:mt-32 
              mt-16 
              sections__section__image-and-text 
              section section__${type} 
              flex flex-col 
              ${section.imageAlignment === 'right' ? 'md:flex-row-reverse' : 'md:flex-row'}`
            }
            data-aos="fade-up" data-aos-once="true" data-aos-offset="10"
            key={key}>
            <img
              className="mb-6 md:mb-0 mb-8 object-cover cover-image"
              srcSet={section.image?.srcSet} alt={section.image?.altText} />
            <div>
              <div
                className={`text text--${section.imageAlignment} isQuote`}
                dangerouslySetInnerHTML={{ __html: section.quoteText }} />
              {/*
                section.imageAndText.arrowlinktext && section.imageAndText.arrowlinkurl &&
                <div className={`text--${section.imageAndText.imageAlignment}`}>
                  <ReadMore label={section.imageAndText.arrowlinktext} link={section.imageAndText.arrowlinkurl}/>
                </div>
              */}
            </div>
          </div>
        )
      case 'ImageAndText':
        return (
          <div
            className={
              `md:mt-32 
              mt-16 
              sections__section__image-and-text 
              section section__${type} 
              flex flex-col 
              ${section.imageAlignment === 'right' ? 'md:flex-row-reverse' : 'md:flex-row'}`
            }
            data-aos="fade-up" data-aos-once="true" data-aos-offset="10"
            key={key}>
            <img
              className="mb-6 md:mb-0 mb-8 object-cover cover-image"
              srcSet={section.image?.srcSet} alt={section.image?.altText} />
            <div>
              <div
                className={`text text--${section.imageAlignment}`}
                dangerouslySetInnerHTML={{ __html: section.text }} />
              {
                section.readMore.label && section.readMore.link &&
                <div className={`text--${section.imageAlignment}`}>
                  <ReadMore label={section.readMore.label} link={section.readMore.link.link}/>
                </div>
              }
            </div>
          </div>
        )
      case 'BackgroundSection':
        return (
          <div className={`md:mt-32 mt-16 md:-mx-20 -mx-8 md:py-32 py-10 section section__${type} bg-color`}
            style={{ backgroundColor: section.backgroundColor }} key={key}
            data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
            <div className="md:mb-10 mb-6" dangerouslySetInnerHTML={{ __html: section.header }} />
            <div dangerouslySetInnerHTML={{ __html: section.text }} />
          </div>
        )
      case 'ColumnsList':
        return (
          <div className={`md:mt-32 mt-16 md:px-0 px-8 md:py-32 py-10 md:-mx-20 -mx-8 section section__${type}`}
            style={{ backgroundColor: section.backgroudColor }} key={key}
            data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
            <div className="md:mb-16 mb-10" dangerouslySetInnerHTML={{ __html: section.header }} />
            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-40">
              {
                section.columns.map((column, index) => {
                  return (
                    <div className={`section__${type}__column md:mb-0 mb-10`} key={index}>
                      <div className="md:mb-6" dangerouslySetInnerHTML={{ __html: column.header }} />
                      <div dangerouslySetInnerHTML={{ __html: column.text }} />
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      case 'IconsSection':
        return (
          <div className={`md:mt-32 mt-16 section section__${type}`} key={key}
            data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
            <div className="md:mb-12 mb-10" dangerouslySetInnerHTML={{ __html: section.header }} />
            <div className="grid md:grid-cols-3 grid-cols-1 md:gap-28 gap-10">
              {
                section.iconGroup.map((group, index) => {
                  return (
                    <div key={index} className="flex flex-col items-center">
                      <img className="mb-4"
                        style={{ height: '45px', width: '40px' }}
                        height={45} width={40}
                        srcSet={group.icon.sourceUrl} alt={group.icon.altText}
                      />
                      <div dangerouslySetInnerHTML={{ __html: group.header }} />
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
    }
  }

  return (
    <div className="about">
      <Hero
        title={pageAbout.hero.header}
        background={pageAbout.hero.backgroundImage?.sourceUrl} />
      {
        pageAbout.aboutSections.map((section, index) => {
          const typeHeirarchy = section['__typename'].split('_')
          const sectionType = typeHeirarchy[typeHeirarchy.length - 1]
          return (
            getSection(section, sectionType, index)
          )
        })
      }
    </div>
  )
}

TimeInvestAbout.propTypes = {
  page: PropTypes.object.isRequired
}

export default TimeInvestAbout
