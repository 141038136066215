import React from 'react';
import PropTypes from 'prop-types';

const Timeline = ({header, years, backgroundColor}) => {
  const style = {}
  if (backgroundColor) {
    style.backgroundColor = backgroundColor
    style.color = 'white'
  }
  years = years.reverse()

  return (
    <div className="py-12 lg:py-24 px-8" style={style}>
      <h2 className="text-white md:text-5xl font-bold md:text-center mb-8 lg:mb-16 ">{ header }</h2>
      <div className="timeline">
        {
          years.map((year, index) => {
            const isLastClass = index === years.length - 1 ? 'last' : '';
            return (
              <div className={`timeline__container ${index%2 > 0 ? 'timeline__left' : 'timeline__right'} ${isLastClass}`} key={index}>
                <div className="timeline__content">
                  <p className="timeline__year__header"><div className="circle" /><span>{year.year}</span></p>
                  <p className={'timeline__year__text'}>{year.text}</p>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

Timeline.propTypes = {
  header: PropTypes.string.isRequired,
  years: PropTypes.array.isRequired,
  backgroundColor: PropTypes.string
}

export default Timeline;
