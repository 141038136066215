import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import Button from './Button';

const axios = require('axios');

const MailchimpForm = ({data}) => {
  const [formFields, setFields] = useState([]);
  const [formInterests, setInterests] = useState([]);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [warning, setWarning] = useState(false);
  const [consented, setConsented] = useState(false)
  const wpAjaxUrl = `https://${process.env.HOSTNAME}/wp-admin/admin-ajax.php`;
  
  useEffect(() => {
    async function fetchInfo() {
      let payload = new FormData;
      payload.append('action', 'mailchimp_get_form_info');
      payload.append('list_id', data.listId);
      const result = await axios.post( wpAjaxUrl, payload );
      if (result && result.status == 200) {
        setFields(result.data.fields);
        setInterests(result.data.interests);
      }
    }
    fetchInfo();
  }, [])

  const subscribe = async function () {
    setMessage('');
    if(formInterests.filter( i => i.checked ).length < 1) {
      setWarning(true);
      setMessage(data.messages.msgInterests);
      return false;
    }
    if(!consented) {
      setWarning(true);
      setMessage(data.messages.msgConsent);
      return false;
    }
    setWarning(false);
    setMessage(data.messages.msgSubscribing);

    let payload = new FormData;
    payload.append('action', 'mailchimp_subscribe');
    payload.append('list_id', data.listId)
    payload.append('fields[EMAIL]', email);
    formFields.map( (f) => {
      payload.append(`fields[${f.tag}]`, f.value);
    } );
    formInterests.map( (i) => {
      if (i.checked) {
        payload.append(`interests[${i.id}]`, i.id);
      }
    } );
    payload.append('consented', consented);

    const result = await axios.post( wpAjaxUrl, payload );
    if (result && result.status == 200) {
      if(result.data.status == 'subscribed') {
        setWarning(false);
        setMessage(data.messages.msgSuccess);
      } else {
        setWarning(true);
        setMessage(result.data.message);
      }
    } else {
      setWarning(true);
      setMessage('Unknown error');
    }
  }

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        subscribe();
      }}
    >
      <div
        style={{ backgroundColor: '#f7f7f7' }}
        className='newsletter-form lg:py-32 py-16 xl:px-36 lg:px-16 px-8 grid lg:grid-cols-3 lg:gap-20 xl:-mx-36 lg:-mx-16 -mx-8 lg:mt-32 mt-16'
      >
        <div className='mb-6'>
          <h2 className="mb-4">{data.header || 'Nyhedsmail sign-up'}</h2>
          <p>{data.text || 'Skriv dig op her. Så sender vi vores seneste nyheder.'}</p>
          <Input
            required={true}
            element='input'
            name='EMAIL'
            type='email'
            value={email}
            placeholder={data.messages.msgEmailPlaceholder || 'Din mailadresse'}
            callback={(inputResponse) => setEmail(inputResponse)}
            extraClasses='newsletter-form__input'
          />

          {
            formFields && formFields.length &&
            formFields.map((fld, index) => (
              <Input
                key={index}
                element='input'
                name={fld.tag}
                type={fld.type}
                value={fld.value}
                placeholder={fld.help_text}
                callback={(inputResponse) => { 
                  setFields(
                    formFields.map( (f) =>
                      f.tag === fld.tag
                        ? { ...f, value: inputResponse }
                        : { ...f }
                    )
                  );
                }}
                extraClasses='newsletter-form__input'
              />
            ))
          }
        </div>
        <div className='mb-6'>
          <h2 className="mb-4">Interesseområder</h2>
          {
            formInterests && formInterests.length &&
            formInterests.map((interest, index) => (
              <div className='newsletter-form__checkbox mb-6' key={index}>
                <Input
                  onChange={(e) => { 
                    setInterests(
                      formInterests.map( (i) =>
                        i.id === interest.id
                          ? { ...i, checked: e.target.checked }
                          : { ...i }
                      )
                    );
                  }}
                  checked={interest.checked}
                  label={<div dangerouslySetInnerHTML={{ __html: interest.name }} />}
                  element="checkbox"
                  name={`chk_${index}[]`}
                  value={interest.id}
                />
              </div>
            ))
          }
        </div>

        <div>
          <Button classes={['mb-2', 'button', 'normal', 'ease-in-out', 'transition-all', 'duration-200', 'rounded-3xl', 'py-3']} label={data.signupLabel || 'Tilmeld'} cb={() => {}}/> 
          <Input
            onChange={(e) => {
              setConsented(e.target.checked)
            }}
            checked={consented}
            label={<div dangerouslySetInnerHTML={{ __html: data.consentText }} />}
            element="checkbox"
            name="consent" 
          />          
          <div className={`newsletter-form__message${warning ? ' warning' : ''}`}>{message}</div>
        </div>
        
      </div>
    </form>
  );
}

MailchimpForm.propTypes = {
  data: PropTypes.any.isRequired,
}

export default MailchimpForm;