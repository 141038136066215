import React, {useEffect, useState} from 'react';
import {ApolloClient, ApolloLink, HttpLink} from '@apollo/client';
import {
  GET_ABOUT_PAGE,
  GET_CONTACT_PAGE, GET_EMPLOYEES,
  GET_PAGE,
  GET_SUBSITE_PAGES, GET_TIMERIGHT_PAGE_SECTIONS
} from '../../../gql';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import {onError} from '@apollo/client/link/error';
import {Query} from '@apollo/client/react/components/Query'
import {InMemoryCache} from 'apollo-cache-inmemory';
import {LoadingIndicator, Error, AppShell} from '../../components';
import NotFound from '../404';
import NewsPost from '../NewsPost';
import TimerightDefault from './TimerightDefault';
import Contact from '../Contact';
import News from '../News';
import GetStarted from '../GetStarted';
//import DefaultPage from '../default';
import AboutTeam from '../AboutTeam';
//import TimeInvestAbout from '../TimeInvest/About';
import About from '../About';

const devMode = process.env.NODE_ENV === 'development';

// custom error handling, only logging errors atm
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // do something with graphql error
    console.error({ graphQLErrors });
  }

  if (networkError) {
    // do something with network error
    console.error({ networkError });
  }
});

const httpLink = new HttpLink({
  uri: devMode
    ? 'https://staging.timegruppen.twentyfour.dev/timeright/graphql'
    : `https://${process.env.HOSTNAME}/timeright/graphql`
});

const link = ApolloLink.from([
  errorLink,
  httpLink,
]);

const cache = new InMemoryCache({
  introspectionQueryResultData: {
    __schema: {
      types: []
    }
  }
});

const client = new ApolloClient({
  cache,
  link,
  resolvers: {},
});

const TimerightQuery = ({children, page}) => {

  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)

  let query = GET_TIMERIGHT_PAGE_SECTIONS
  const options = {stringId: page.id}

  //console.log('timeRight page.uri', page.uri);
  switch (page.uri) {
    case '/':
      query = GET_TIMERIGHT_PAGE_SECTIONS
      break

    case '/kontakt/':
      query = GET_CONTACT_PAGE
      break

    case '/om-os/':
      query = GET_ABOUT_PAGE
      break
    case '/om-os/vores-team/':
      query = GET_EMPLOYEES
      break

    default:
      query = GET_PAGE
  }

  useEffect(() => {
    client.query({ query: query, variables: options })
      .then((result) => {
        setLoading(false)
        setResult(result)
      })
      .catch((error) => {
        setError(error)
      })
  }, [])

  if (loading || !result) {
    return <div>
      <div className="full-page-spinner"><LoadingIndicator /></div>
      <div style={{ position: 'relative', height: '100vh', width: '100vw', display: 'block'}} />
    </div>
  } else if (error) {
    return <Error />
  }

  return children(result.data)
}

TimerightQuery.propTypes = {
  page: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired
}

const TimerightRouter = ({data}) => {
  let { path } = useRouteMatch();


  const renderRoutes = (page) => {
    const pathLocation = '/timeright' + page.uri;
    switch (pathLocation) {
      case '/timeright/':
        return <Route exact key={page.id} path={path+page.uri}><TimerightQuery page={page}>{(data) =>
          <>
            <TimerightDefault page={data.page} />
          </>
        }</TimerightQuery></Route>

      case '/timeright/kontakt/':
        return <Route key={page.id} path={'/timeright/kontakt/'}>
          <TimerightQuery page={page}>{(data) => <Contact data={data} area="timeright" /> }</TimerightQuery>
        </Route>
      case '/timeright/kom-igang/':
        return <Route key={page.id} path={'/timeright/kom-igang/'}>
          <GetStarted area="timeright" />
        </Route>
      case '/timeright/om-os/':
        return <Route key={page.id} path={'/timeright/om-os/'}>
          <TimerightQuery page={page}>{(data) =>
            <About data={data}/>
          }</TimerightQuery>
        </Route>
      case '/timeright/om-os/vores-team/':
        return <Route key={page.id} path={'/timeright/om-os/vores-team/'}>
          <TimerightQuery page={page}>{(data) => <AboutTeam data={data}/>}</TimerightQuery>
        </Route>

      case '/timeright/videnscenter/':
        return (
          <Route key={page.id} path={'/timeright/videnscenter/'}>
            <TimerightQuery page={page}>{(data) => <News page={data} subsite="TR"/>}</TimerightQuery>
          </Route>
        )

      case '/timeright/privatlivspolitik/':
        return (
          <Route key={page.id} path={'/timeright/privatlivspolitik/'}>
            <div className="md:mt-14 mt-10" dangerouslySetInnerHTML={{ __html: page.content }} />
          </Route>
        )

      default:

        return <Route exact key={page.id} path={pathLocation}><TimerightQuery page={page}>{(data) =>
          <TimerightDefault page={data.page} />
        }</TimerightQuery>
        </Route>
    }
  }
  return (
    <>
      <Switch>
        <Route path={'/timeright/blog/:id'} ><NewsPost subsite="TR" /></Route>
        {
          data.pages.nodes.map((page) => renderRoutes(page))
        }
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

TimerightRouter.propTypes = {
  data: PropTypes.object.isRequired
}

const TimerightHome = () => {
  return (
    <Query query={GET_SUBSITE_PAGES} client={client}>
      {

        ({ loading, error, data }) => {
          if (!data && loading || error) {
            return <AppShell loading={loading} hidenav={true} />
          }

          return <TimerightRouter data={data} />
        }
      }
    </Query>
  );

};

export default TimerightHome;
