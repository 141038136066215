import { gql } from '@apollo/client';

const SEND_EMAIL = gql`
    mutation SEND_EMAIL($to: String, $subject: String, $body: String) {
        sendEmail(
            input: {
                to: $to
                subject: $subject
                body: $body
            }
        ) {
            origin
            sent
            message
        }
    }
`;

const GET_STARTED = gql`
    mutation SEND_EMAIL($to: String, $subject: String, $body: String) {
        sendEmail(
            input: {
                to: $to
                subject: $subject
                body: $body
            }
        ) {
            origin
            sent
            message
        }
    }
`;

export {SEND_EMAIL, GET_STARTED}
