import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {OptionsSelector, Hero, InvestKnowledgeSlider, ReadMore, MailchimpForm} from '../../components';

const InvestDefault = ({page}) => {

  useEffect(() => {
    if (page && page.seo && page.seo.title ) {
      document.title = page.seo.title;
    }
    return () => {}
  }, [page])

  return (
    <>
      {
        page.pageSections?.sections?.map((section, index) => {
          switch(section.fieldGroupName) {
            case 'page_Pagesections_Sections_Hero':
              return <Hero
                title={section.title}
                background={section.backgroundImage?.sourceUrl} key={index}/>
            case 'page_Pagesections_Sections_HeaderAndTextSection':
              return (
                <div className={
                  `sections__section md:mt-32 mt-16 ${section.backgroundColor ? 'md:py-10 py-6 md:pl-24 px-6' : ''}`
                }
                data-aos="fade-up" data-aos-once="true" data-aos-offset="10"
                style={section.backgroundColor ? {backgroundColor: section.backgroundColor} : null} key={index}>
                  {section.header &&
                  <div className="sections__header" dangerouslySetInnerHTML={{__html: section.header}}/>}
                  {section.text && (
                    <div
                      className={`sections__section__textColumns length-1 mt-16 ${section.centerAlignText ? 'flex flex-col items-center' : ''}`}
                      dangerouslySetInnerHTML={{__html: section.text}}/>
                  )}
                </div>
              )
            case 'page_Pagesections_Sections_ImageSection':
              return (
                <div className="sections__section md:mt-32 mt-16 flex flex-col justify-center items-center overflow-hidden"
                  data-aos="fade-up" data-aos-once="true" data-aos-offset="10"
                  style={section.fullWidth ? {maxHeight: '528px'} : null} key={index}>
                  <img className={`${!section.fullWidth ? 'md:w-1/2' : 'object-cover'} w-full`} srcSet={section.image.srcSet} alt={section.image.altText} style={section.maxWidth ? {maxWidth: `${section.maxWidth}px`} : null} />
                </div>
              )
            case 'page_Pagesections_Sections_OptionSelectorSection':
              return <OptionsSelector data={section} type={section.hourglass ? 'hourglass' : 'backgroundImage'} key={index}/>
            case 'page_Pagesections_Sections_WatermarkBackgroundSection':
            {
              const style = {}
              if (section.watermarkImage) style.backgroundImage = `url(${section.watermarkImage.sourceUrl})`
              if (section.backgroundColor) style.backgroundColor = section.backgroundColor
              return (
                <div
                  style={style}
                  className={
                      `sections__section 
                       md:mt-32 mt-16 
                       md:py-32 py-10 
                       lg:px-32 md:px-16 px-6 
                       bg-color 
                       ${section.watermarkImage ? 'bg-image bg-image--watermark' : ''} 
                       ${section.inlineImage ? 'md:flex' : ''}`
                  }
                  data-aos="fade-up" data-aos-once="true" data-aos-offset="10" key={index}>
                  <div dangerouslySetInnerHTML={{__html: section.text}}/>
                  {section.inlineImage && <img className="inline-image" srcSet={section.inlineImage.srcSet} alt={section.inlineImage.altText} />}
                </div>
              )
            }
            case 'page_Pagesections_Sections_KnowledgeSliderSection':
              return (
                <div
                  style={{
                    backgroundColor: section.backgroundColor
                  }}
                  className={`sections__section bg-color md:mt-32 mt-16 md:py-32 py-10 ${section.fullWidth ? 'xl:-mx-36 md:-mx-16 -mx-8' : ''}`}
                  data-aos="fade-up" data-aos-once="true" data-aos-offset="10" key={index}>
                  <InvestKnowledgeSlider data={section} />
                </div>
              )
            case 'page_Pagesections_Sections_IconsSection':
              return (
                <div className={`sections__section__columns grid grid-cols-1 md:grid-cols-${section.iconGroup.length} md:gap-20 gap-10 md:mt-12 mt-10`}
                  data-aos="fade-up" data-aos-once="true" data-aos-offset="10" key={index}>
                  {section.iconGroup.map((group, index) => (
                    <div key={index} className="flex flex-col items-center px-0">
                      <img width={60} height={60} style={{ width: '60px', height: '60px' }} src={group.icon?.sourceUrl} alt={group.icon?.altText || 'column.text'} />
                      {group.header && <h4 className="text-center my-4">{group.header}</h4>}
                      <p className="text-center mt-4" dangerouslySetInnerHTML={{ __html: group.text }}/>
                    </div>
                  ))}
                </div>
              )
            case 'page_Pagesections_Sections_ColumnsSection':
            {
              const style = {}
              if (section.backgroundColor) style.backgroundColor = section.backgroundColor
              return (
                <div style={style}
                  className="sections__section md:mt-32 mt-16 md:py-32 py-10 md:px-20 px-4" key={index}>
                  {
                    section.header
                        && <div className="md:mb-10 mb-4" dangerouslySetInnerHTML={{__html: section.header}}/>
                  }
                  {
                    section.columns.length > 0 &&
                      <div className={`grid md:grid-cols-${section.columns.length} grid-cols-1 md:gap-14 gap-6`}>
                        {
                          section.columns.map((column, index) => (
                            <div dangerouslySetInnerHTML={{ __html: column.content }} key={index}/>
                          ))
                        }
                      </div>
                  }
                </div>
              )
            }
            case 'page_Pagesections_Sections_ImageTextColumns':
              return (
                <div className={
                  `sections__section__image-and-text 
                   md:mt-32 mt-16 flex flex-col 
                   ${section.imageAlignment === 'right' ? 'md:flex-row-reverse md:pl-20' : 'md:flex-row md:pr-20'}`
                } data-aos="fade-up" data-aos-once="true" data-aos-offset="10" key={index}>
                  <img
                    className="mb-6 md:mb-0 cover-image"
                    srcSet={section.image?.srcSet} alt={section.image?.altText} />
                  <div>
                    {section.header && <div className={`header header--${section.imageAlignment}`} dangerouslySetInnerHTML={{ __html: section.header }} />}
                    <div
                      className={`text text--${section.imageAlignment}`}
                      dangerouslySetInnerHTML={{ __html: section.text }} />
                    {
                      section.readMore && section.readMore.link &&
                      <div className={`text--${section.imageAlignment}`}>
                        <ReadMore label={section.readMore.label} link={section.readMore.link.link} />
                      </div>
                    }
                  </div>
                </div>
              )
            case 'page_Pagesections_Sections_NewsletterSignup':
              return (
                <div className={`sections__section__newsletter 
                    md:mt-32 mt-16 flex flex-col`} data-aos="fade-up" data-aos-once="true" data-aos-offset="10" key={index}>
                  <MailchimpForm data={section}/>
                </div>
              )
          }
        })
      }
    </>
  )
}

InvestDefault.propTypes = {
  page: PropTypes.object.isRequired
}

export default InvestDefault
