import React from 'react';
import PropTypes from 'prop-types';

const FooterMenus = ({data}) => {
  return (
    <div className="footer__menus xl:px-36 md:px-16 px-8">
      {data.topMenu.header && <h3 className="mb-2 footer__main-item">{data.topMenu.header}</h3>}
      {data.topMenu.items && data.topMenu.items.length && <div className={'subsite-meus grid md:grid-cols-4 grid-cols-1'}>
        {
          data.topMenu.items.map((menu, index) => {
            return (
              <div className="md:mb-0 mb-10" key={index}>
                {
                  menu.group.map((item, itemIndex) => (
                    <div key={itemIndex}>
                      <a href={item.link} className="footer__sub-item">{item.label}</a>
                    </div>
                  ))
                }
              </div>
            );
          })
        }
      </div>}
    </div>
  )
}

FooterMenus.propTypes = {
  data: PropTypes.object.isRequired
}

export default FooterMenus;
