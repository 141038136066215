import React from 'react';
import PropTypes from 'prop-types';
import VimeoVideo from './VimeoVideo';

const VideoAndText = ({videoPlacementLeft, headline, text, vimeoVideo}) => {
  return (
    <section className="videotext">
      {videoPlacementLeft ? (
        <article className="flex flex-col md:flex-row">
          <div className="mb-10 w-full md:flex-shrink-0 md:mr-40 md:w-512">
            <VimeoVideo vimeoVideoId={vimeoVideo.vimeoVideoId} vimeoVideoPoster={vimeoVideo.vimeoVideoPoster} />
          </div>
          <div className="mb-0 md:pr-20">
            <h2 className="md:mb-4">{headline}</h2>
            <div className={'videotext__text'} dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </article>

      ) : (
        <article className="flex flex-col flex-col-reverse md:flex-row">
          <div className="mb-10 md:mb-0 md:pl-20">
            <h2 className="md:mb-4">{headline}</h2>
            <div className={'videotext__text'} dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          <div className="mb-10 w-full md:flex-shrink-0 md:ml-40 md:w-512 md:mb-0">
            <VimeoVideo vimeoVideoId={vimeoVideo.vimeoVideoId} vimeoVideoPoster={vimeoVideo.vimeoVideoPoster} />
          </div>
        </article>
      )}
    </section>
  )
}

VideoAndText.propTypes = {
  videoPlacementLeft: PropTypes.any,
  headline: PropTypes.string,
  text: PropTypes.string,
  vimeoVideo: PropTypes.object,
}

export default VideoAndText
