import { gql } from '@apollo/client';

const post = `
    id
    date
    featuredImage {
        node {
            altText
            sourceUrl(size: LARGE)
            srcSet(size: LARGE)
        }
    }
    title
    excerpt
    newsPost {
        dateLabel
        external {
            externalArticle
            externalLink
        }
    }
    content
    categories {
        nodes {
            name
        }
    }
    uri
    slug
    link
`;

const GET_POST = gql`
    query getPost($stringId: ID!) {
        post(id: $stringId, idType: SLUG) {
            id
            date
            featuredImage {
                node {
                    altText
                    sourceUrl(size: LARGE)
                    srcSet(size: LARGE)
                }
            }
            title
            excerpt
            newsPost {
                dateLabel
                external {
                    externalArticle
                    externalLink
                }
            }
            content
            categories {
                nodes {
                    name
                }
            }
            uri
            slug
            link
        }
    }
`;

const GET_NEWS_PAGE = gql`
  query getPosts($stringId: ID!, $first: Int!) {
      page(id: $stringId) {
          title
          seo {
            title
          }
          pageBlocks {
              hero {
                  header
                  backgroundImage {
                      sourceUrl(size: LARGE)
                      srcSet(size: LARGE)
                  }
              }
          }
      }
      posts(first: $first) {
          nodes {
              ${post}
          }
          pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
          }
      }
  }
`;

const GET_POSTS_PAGINATED = gql`
    query getPosts(
        $first: Int
        $last: Int
        $after: String
        $before: String
    ) {
        posts(first: $first, last: $last, after: $after, before: $before) {
            nodes {
                id
                date
                featuredImage {
                    node {
                        altText
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
                title
                excerpt
                newsPost {
                    dateLabel
                    external {
                        externalArticle
                        externalLink
                    }
                }
                content
                categories {
                    nodes {
                        name
                    }
                }
                uri
                slug
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;

const GET_POSTS = gql`
    query getPosts {
        posts(last: 500) {
            nodes {
                id
                date
                featuredImage {
                    node {
                        altText
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
                title
                excerpt
                newsPost {
                    dateLabel
                    external {
                        externalArticle
                        externalLink
                    }
                }
                content
                categories {
                    nodes {
                        name
                    }
                }
                uri
                slug
                link
            }
        }
    }
`;

export {GET_NEWS_PAGE, GET_POSTS_PAGINATED, GET_POST, GET_POSTS}
