import React from 'react';
import PropTypes from 'prop-types';

const ColumnsSection = ({section}) => {
  const style = {}
  if (section.backgroundColor) style.backgroundColor = section.backgroundColor
  return (
    <div style={style}
      className="sections__section md:mt-32 mt-16 md:py-32 py-10 md:px-20 px-4"
      data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
      {
        section.header
                && <div className="md:mb-10 mb-4" dangerouslySetInnerHTML={{__html: section.header}}/>
      }
      {
        section.columns.length > 0 &&
                <div className={`grid md:grid-cols-${section.columns.length} grid-cols-1 md:gap-14 gap-6`}>
                  {
                    section.columns.map((column, index) => (
                      <div dangerouslySetInnerHTML={{ __html: column.content }} key={index}/>
                    ))
                  }
                </div>
      }
    </div>
  );
}

ColumnsSection.propTypes = {
  section: PropTypes.any,
};

export default ColumnsSection;
