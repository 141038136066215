import React from 'react';
import PropTypes from 'prop-types';

const IconsSection = ({section}) => {
  return (
    <div className={`sections__section__columns grid grid-cols-1 md:grid-cols-${section.iconGroup.length} md:gap-20 gap-10 md:mt-12 mt-10`}
      data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
      {section.iconGroup.map((group, index) => (
        <div key={index} className="flex flex-col items-center px-0">
          <img width={60} height={60} style={{ width: '60px', height: '60px' }} src={group.icon?.sourceUrl} alt={group.icon?.altText || 'column.text'} />
          {group.header && <h4 className="text-center my-4">{group.header}</h4>}
          <p className="text-center mt-4" dangerouslySetInnerHTML={{ __html: group.text.replace(/\n/g,'<br />') }} />
        </div>
      ))}
    </div>
  );
}

IconsSection.propTypes = {
  section: PropTypes.any,
};

export default IconsSection;


