import React, { useEffect, useState } from 'react';
import FooterCTA from './FooterCTA';
import FooterMenus from './FooterMenus';
import FooterContact from './FooterContact';
import FooterCustomers from './FooterCustomers';
import { GET_THEMEOPTIONS } from '../../gql';
import { useSiteClient } from '../hooks/UseSiteQuery';
import FooterTopMenu from './FooterTopMenu';

const Footer = () => {
  const client = useSiteClient()
  const [data, setQueryObject] = useState(undefined)
  const [loading, setLoading] = useState(true) // eslint-disable-line no-unused-vars

  useEffect(() => {
    if (client && !data) {
      client.query({ query: GET_THEMEOPTIONS, client: client })
        .then((result) => {
          setLoading(false)
          setQueryObject(result.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [client])

  if (!data) return null

  return (
    <div>
      {
        data.themeOptions.footerCTA && <FooterCTA cta={ data.themeOptions.footerCTA.footerCta } />
      }
      {
        data.themeOptions.footerCustomers && data.themeOptions.footerCustomers.showCustomers &&
        <FooterCustomers data={ data.themeOptions.footerCustomers.customers } />
      }

      {
        data.themeOptions.footerMenus && data.themeOptions.footerMenus.topMenu && <FooterTopMenu data={ data.themeOptions.footerMenus } />
      }
      
      {
        data.themeOptions.footerContact && <FooterContact data={ data.themeOptions.footerContact } />
      }
      {
        data.themeOptions.footerMenus && <FooterMenus data={ data.themeOptions.footerMenus } />
      }
      {
        data.themeOptions.footerCopyright && <div className="footer__copyright lg:px-36 md:px-20 px-8">{ data.themeOptions.footerCopyright.copyright }</div>
      }
    </div>
  )
}

export default Footer
