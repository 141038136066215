import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Hero, NewsletterForm, PageSections} from '../components';
import {FRONTPAGE_HERO_ITEMS_LIMIT, FRONTPAGE_HERO_ITEMS} from '../../js/constants';

const Frontpage = ({data}) => {
  useEffect(() => {    
    if (data && data.page && data.page.seo && data.page.seo.title ) {
      document.title = data.page.seo.title;
    }
    return () => {}
  }, [data])
  const [heroText, setHeroText] = useState(data.page.pageBlocks.hero.header)

  return (
    <>
      <Hero
        title={heroText}
        background={data.page.pageBlocks.hero.backgroundImage.sourceUrl}
        extraBackgrounds={data.page.pageBlocks.services.map( row => row['image'] )}
      />
      <div className={`services grid md:grid-cols-2 lg:grid-cols-${(data.page.pageBlocks.services.length <= FRONTPAGE_HERO_ITEMS_LIMIT) ? data.page.pageBlocks.services.length : FRONTPAGE_HERO_ITEMS} grid-cols-1 md:p-3`}>
        {
          data.page.pageBlocks.services.map((service, index) => (
            <a
              href={service.link}
              key={index}
              className={'services__service p-6 lg:p-3'}
              onMouseEnter={ () => 
              {
                service.text && setHeroText('<h1>' + service.text.replace(/(<([^>]+)>)/gi, '') + '</h1>');
                if(document.getElementById(`hero-extra-bg-id-${index}`)) {
                  document.getElementById(`hero-extra-bg-id-${index}`).className = 'active';
                }
              } 
              }
              onMouseLeave={ () =>
              {
                service.text && setHeroText(data.page.pageBlocks.hero.header);
                if(document.getElementById(`hero-extra-bg-id-${index}`)) {
                  document.getElementById(`hero-extra-bg-id-${index}`).className = 'inactive';
                }
              }
              }>
              <h2>{service.header}</h2>
              <div className="header__separator" />
              <div dangerouslySetInnerHTML={{ __html: service.text }} />
            </a>
          ))
        }
      </div>
      <div className="sections">
        <PageSections sections={data.page.pageBlocks.sections} />
      </div>
      { data.page.freshmail && data.page.freshmail.showNewsletterForm && <NewsletterForm data={ data.page.freshmail } /> }
    </>
  )
}

Frontpage.propTypes = {
  data: PropTypes.object.isRequired
}

export default Frontpage;
