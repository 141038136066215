import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '../index';
import ReadMore from '../ReadMore';


const TextColumns = ({section}) => {
  return (
    <div className={`
                    sections__section md:mt-32 mt-16
                    sections__section__textColumns 
                    length-${section.textColumns.length} 
                    grid grid-cols-1 md:grid-cols-${section.textColumns.length} gap-10 
                    
                  `}
    data-aos="fade-up" data-aos-once="true" data-aos-offset="10"
    >
      {section.textColumns.map((column, index) => {
        return (
          <div key={index}>
            <p className={`${column.alignment === 'center: Center' ? 'text-center' : ''}`} key={index} dangerouslySetInnerHTML={{ __html: column.text.replace(/\n/g,'<br />')}} />
            {
              column.linkButton.label && column.linkButton.link && (
                <>
                  {
                    column.linkButton.showButton
                      ? <Button label={column.linkButton.label} cb={() => window.open(column.linkButton.link, '_blank').focus()} />
                      : <ReadMore label={column.linkButton.label} link={column.linkButton.link} external={true} />
                  }
                </>
              )
            }
          </div>
        )
      })}
    </div>
  );
}

TextColumns.propTypes = {
  section: PropTypes.any,
};

export default TextColumns;







/*
{
    section.textColumns && (
        <div className={`
                    sections__section__textColumns
                    length-${section.textColumns.length}
                    grid grid-cols-1 md:grid-cols-${section.textColumns.length} gap-10
                    md:mt-16 mt-6
                  `}>
            {section.textColumns.map((column, index) => {
                return (
                    <>
                        <p className={`${column.alignment === 'center: Center' ? 'text-center' : ''}`} key={index} dangerouslySetInnerHTML={{ __html: column.text.replace(/\n/g,'<br />')}} />
                        {
                            column.linkButton.label && column.linkButton.link && (
                                <>
                                    {
                                        column.linkButton.showButton
                                            ? <Button label={column.linkButton.label} cb={() => window.open(column.linkButton.link, '_blank').focus()} />
                                            : <ReadMore label={column.linkButton.label} link={column.linkButton.link} external={true} />
                                    }
                                </>
                            )
                        }
                    </>
                )
            })}
        </div>
    )
}*/
