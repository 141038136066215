import React, {useState, useRef, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import SlideArrow from '../../assets/images/SlideArrow';
import {Hero} from '../components';
import SiteContext from '../context/SiteContext';

const AboutTeam = ({data, invest = false}) => { // eslint-disable-line no-unused-vars
  const [search, setSearch] = useState('')
  const [currentTag, setCurrentTag] = useState(null)
  const [tagFilterVisible, setTagFilterVisible] = useState(false)
  const [tags, setTags] = useState([]) // eslint-disable-line no-unused-vars
  const [employees, setEmployees] = useState([])
  const dropdown = useRef()
  const slider = useRef()
  const { site } = useContext(SiteContext);

  useEffect(() => {
    // if (data && data.page && data.page.title) {
    //   document.title = data.page.title;
    // }

    if (data && data.page && data.page.seo && data.page.seo.title ) {
      document.title = data.page.seo.title;
    }
    return () => {}
  }, [data])

  useEffect(() => {
    data.employees && data.employees.nodes && data.employees.nodes.map((node) => {
      //const tag = node.tags.nodes[0].name
      node.tags.nodes.forEach((tag) => {
        if (!tags.includes(tag.name)) {
          tags.push(tag.name)
        }

        if (site === 'FP') {
          tags.sort()
        }
      })
      /*if (!tags.includes(tag)) {
        tags.push(tag);
      }*/
    })
    populateAll()
  }, [data])

  const populateAll = () => {
    let temp = []
    data.employees.nodes.map((item) => {
      if (!item.employee.hideFromAll) {
        temp.push(item)
      }
    })
    temp = temp.sort((a, b) => {
      const nameA = a.employee.name.toLowerCase()
      const nameB = b.employee.name.toLowerCase()
      return (nameA < nameB) ? -1 : (nameA > nameB) ? 1: 0
    })
    setEmployees(temp)
  }

  const clearSearch = () => {
    populateAll()
    //setEmployees(data.employees.nodes)
  }

  const onSearch = () => {
    if (search.length > 2) {
      const temp = data.employees.nodes.filter((node) => {
        return node.employee.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      })

      let sorted = temp.sort((a, b) => {
        const nameA = a.employee.name.toLowerCase()
        const nameB = b.employee.name.toLowerCase()
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1: 0
      })

      setEmployees(sorted)
    }
    if (search.length === 0) {
      clearSearch()
    }
  }

  const onFilter = (tag) => {
    setCurrentTag(tag)
    setTagFilterVisible(false)
    if (tag === null) {
      populateAll()
      return
    }
    const temp = data.employees.nodes.filter((node) => {
      return node.tags.nodes.filter((tagObj) => {
        return tagObj.name === tag
      }).length > 0
    })

    let sorted = temp.sort((a, b) => {
      const nameA = a.employee.name.toLowerCase()
      const nameB = b.employee.name.toLowerCase()
      return (nameA < nameB) ? -1 : (nameA > nameB) ? 1: 0
    })

    setEmployees(sorted)
  }

  const scrollSlider = (side) => {
    if (side === 'left') {
      if (slider.current.scrollLeft > 295) {
        slider.current.scrollBy({
          left: -295,
          behavior: 'smooth'
        })
      } else if (slider.current.scrollLeft > 0) {
        slider.current.scrollBy({
          left: (-1 * slider.current.scrollLeft),
          behavior: 'smooth'
        })
      }
    } else if (side === 'right') {
      if (slider.current.scrollLeft < (slider.current.scrollWidth - 295)) {
        slider.current.scrollBy({
          left: 295,
          behavior: 'smooth'
        })
      } else if (slider.current.scrollLeft < slider.current.scrollWidth) {
        slider.current.scrollBy({
          left: slider.current.scrollWidth,
          behavior: 'smooth'
        })
      }
    }
  }

  return (
    <div className="employees xl:-mx-36 md:-mx-16 -mx-8">
      <Hero
        title={data.page.pageBlocks.hero.header}
        background={data.page.pageBlocks.hero.backgroundImage.sourceUrl}
        normalMargin={true} />
      <div className="xl:px-36 md:px-20 px-8 md:pb-20 pb-10 md:pt-32 pt-10">
        <div className="input--search">
          <div className="flex items-center">
            <svg enableBackground="new 0 0 32 32" height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m31.597 30.89-12.482-12.482c1.786-1.956 2.885-4.551 2.885-7.408 0-6.075-4.925-11-11-11s-11 4.925-11 11 4.925 11 11 11c2.858 0 5.452-1.099 7.408-2.885l12.482 12.482c.13.13.341.13.471 0l.236-.236c.13-.13.13-.341 0-.471zm-30.597-19.89c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10-10-4.477-10-10z" fill="#ffffff"/></svg>
            <input
              value={search}
              type="text"
              onChange={(e) => {
                const {value} = e.target
                if (value.length === 0) {
                  clearSearch()
                }
                setSearch(value)
              }}
              placeholder="Søg medarbejder"/>
          </div>
          <button className="xsmall" onClick={() => onSearch()}>Søg</button>
        </div>
      </div>

      <div className="flex justify-between items-center md:mb-20 mb-10 md:px-20 px-8">
        <div className="employees__filter">
          <h2 className="mb-4 md:mb-0">{currentTag ? currentTag : 'Alle'}</h2>
          <div className="dropdown dropdown--filter">
            <div className="dropdown__button" onClick={() => setTagFilterVisible(!tagFilterVisible)}>
              <span>Områder</span>
              <div className="caret" />
            </div>
            <div className={`employees__list dropdown__list --absolute ${tagFilterVisible ? 'active' : ''}`} ref={dropdown}>
              <div className="dropdown__item employees__item" onClick={() => onFilter(null)}>Alle</div>
              {tags.map((tag, index) => (
                <div className="dropdown__item employees__item" key={index} onClick={() => onFilter(tag)}>{tag}</div>
              ))}
            </div>
          </div>
        </div>
        <div className="grid-slider__buttons-container no-hide">
          <div className="grid-slider__button grid-slider__button--back" onClick={() => scrollSlider('left')}><SlideArrow color="#ffffff" /></div>
          <div className="grid-slider__button grid-slider__button--next" onClick={() => scrollSlider('right')}><SlideArrow color="#ffffff" /></div>
        </div>
      </div>
      <div
        className="md:pl-20 pl-8 grid-slider grid-rows-1"
        style={{gridTemplateColumns: `repeat(${Math.ceil(employees.length)}, 295px`}}
        ref={slider}>
        {
          employees.map((node, index) => {
            let numberOfGhosts = [];

            if (!node.employee.phone) {
              numberOfGhosts.push(1);
            }

            if (!node.employee.direct) {
              numberOfGhosts.push(2);
            }

            if (!node.employee.mobile) {
              numberOfGhosts.push(3);
            }

            return (
              <div className="employee" key={index}>
                <img className="employee__profile mb-2" src={node.featuredImage?.node?.sourceUrl} alt={node.featuredImage?.node?.altText} />
                <p className="employee__name mb-2">{node.employee.name}</p>
                <p className={`employee__position ${site !== 'TG' ? 'mb-4 md:mb-8' : '' }`}>{node.employee.position}</p>
                {site === 'TG' && node.tags.nodes.length ? <p className="employee__department mb-4 md:mb-8">{node.tags.nodes[0].name}</p> : null}
                <div>
                  {
                    node.employee.phone ?
                      <a href={`tel:${node.employee.phone}`} className='employee__phone'>
                        <span>T: </span><span className="">{node.employee.phone}</span>
                      </a>
                      :
                      <p className="employee__phone"></p>
                  }
                  {
                    node.employee.direct ?
                      <a href={`tel:${node.employee.direct}`} className='employee__phone'>
                        <span>D: </span><span className="">{node.employee.direct}</span>
                      </a>
                      :
                      <p className="employee__phone"></p>
                  }
                  {
                    node.employee.mobile ?
                      <a href={`tel:${node.employee.mobile}`} className='employee__phone'>
                        <span>M: </span><span className="">{node.employee.mobile}</span>
                      </a>
                      :
                      <p className="employee__phone"></p>
                  }
                  {numberOfGhosts.map((ghost, index) => {
                    return (
                      <p className="employee__phone" key={ index }><span className="ghost">{ ghost }</span></p>
                    )
                  })}
                </div>
                <p className="employee__email break-words mt-2">
                  {node.employee.email && <a href={`mailto:${node.employee.email}`}>{node.employee.email}</a>}
                </p>
              </div>
            )})
        }
      </div>
    </div>
  )
}

AboutTeam.propTypes = {
  data: PropTypes.object.isRequired,
  invest: PropTypes.bool
}

export default AboutTeam;
