import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Hero, PageSections} from '../components';

const axios = require('axios');

const AboutCareer = ({data}) => {
  const [jobs, setJobs] = useState([]) // eslint-disable-line no-unused-vars
  const [jobsLoading, setJobsLoading] = useState(true) // eslint-disable-line no-unused-vars
  const [jobsError, setJobsError] = useState(null) // eslint-disable-line no-unused-vars

  const loadScript = (src, name, position) => {
    if (!document.getElementById(name)) {
      const tag = document.createElement('script')
      tag.id = name
      tag.src = src
      tag.async = false
      tag.defer = true

      document.getElementById(`${position}-hrskyen`).append(tag)
    }
  }

  useEffect(() => {
    if (data && data.page && data.page.seo && data.page.seo.title ) {
      document.title = data.page.seo.title;
    }
    return () => {}
  }, [data])

  useEffect(() => {
    axios.get('https://recruit.hr-on.com/api/jobs/timegruppen')
      .then((response) => {
        const temp = Object.keys(response.data);
        temp.map((id) => {
          jobs.push({ id, ...response.data[id]})
        })
      })
      .catch((error) => {
        setJobsError(error)
      })
      .then(() => {
        setJobsLoading(false)

      })
    loadScript('https://recruit.hr-on.com/frame-api/hr.js', 'hr', 'pre')
    loadScript('https://recruit.hr-on.com/frame-api/customers/timegruppenas-v4.js', 'tg', 'post')

  }, [])

  return (
    <>
      <Hero
        title={data.page.pageBlocks.hero.header}
        background={data.page.pageBlocks.hero.backgroundImage.sourceUrl} />
      <PageSections sections={data.page.pageBlocks.sections} />
      <div className="grid grid-cols-1 md:grid-cols-2 mt-10 md:mt-20 gap-x-11">
        <div className="jobs">
          
          <span id="pre-hrskyen" />
          <div id="hrskyen" />
          <a style={{ fontSize: '8px', color: '#aaaaaa', fontFamily: 'verdana, sans-serif', textDecoration: 'none' }}
       href="http://hr-on.com/">
      &copy; e-Recruitment by HR-ON
    </a>
          <span id="post-hrskyen" />
          
          {/*
            jobs.map((job) => {
              return <div key={job.id} className="jobs__posting mb-8"><a><span className="bold">{job.title}</span> - {job.locationwork}</a></div>
            })
          */}
          {/*<div className="mb-10 md:mb-20 jobs__unsolicited">
            <p className="bold mb-6 md:mb-4 header">{data.page.pageCareer.unsolicited.header}</p>
            <p className="mb-4 md:mb-6 text">{data.page.pageCareer.unsolicited.text}</p>
            <Button
              classes={['inverted']}
              type="block"
              label={data.page.pageCareer.unsolicited.button.label}
              cb={() => {
                console.log('do something', data.page.pageCareer.unsolicited.button.link)
              }}/>
          </div>
            <div className="mb-10 md:mb-20 jobs__trainee">
            <p className="bold mb-6 md:mb-4 header">{data.page.pageCareer.trainee.header}</p>
            <p className="mb-4 md:mb-6 text">{data.page.pageCareer.trainee.text}</p>
            <Button
            classes={['inverted']}
            type="block"
            label={data.page.pageCareer.trainee.button.label}
            cb={() => {
            console.log('do something', data.page.pageCareer.trainee.button.link)
          }} />
            </div>*/}
        </div>
        <div>
        {
  data.page.pageCareer.image && data.page.pageCareer.image.srcSet
  ? <img 
      className="jobs__image" 
      srcSet={data.page.pageCareer.image.srcSet} 
      alt={data.page.pageCareer.image.altText} 
    />
  : null
}
        </div>
      </div>
    </>
  )
}

AboutCareer.propTypes = {
  data: PropTypes.object.isRequired
}

export default AboutCareer
