import React from 'react';

const TimeGlass = () => {
  return (
    <svg x="0px"
      y="0px"
      viewBox="0 0 221 221" xmlSpace="preserve"
      className="hourglass-image">
      <g>
        <path fill="#33579A" d="M165.43,189.89c0.02,2.39,0.03,3.97-0.4,4.4c-0.44,0.44-92.53,0.31-105.57,0.43
      c-1.82,0.03-2.73-0.23-3.25-0.76c-0.52-0.53-0.73-1.53-0.67-3.15c0.07-1.83,0.07-3.33,0.08-4.57c0-2.21,0-3.67,0.44-4.09
      c0.37-0.37,1.5-0.44,3.23-0.44c0.56,0,1.17,0.01,1.85,0.01c0.97,0.01,99.68,0.01,99.68,0.01c2.21,0,3.8,0,4.22,0.43
      c0.43,0.43,0.41,2.11,0.38,4.24C165.41,187.75,165.42,188.91,165.43,189.89 M99.78,112.91c1.11-0.52,2.79-1.3,2.7-3.57
      c-0.08-2.05-1.59-2.73-2.68-3.23c-0.21-0.09-0.42-0.19-0.62-0.3c-16.78-8.86-27.76-22.69-32.64-41.09
      c-1.52-5.73-2.13-11.99-1.86-19.14c0.06-1.64,0.49-2.1,2.07-2.1c0.05,0,0.1,0,0.15,0c3.5,0.06,7.03,0.04,10.53,0.03l4.54-0.01
      l70.43,0c3.92,0,3.92,0,3.95,4.07c0.17,25.77-11.31,45.34-34.12,58.15c-0.25,0.14-0.53,0.26-0.81,0.38
      c-1.1,0.47-2.76,1.18-2.82,3.32c-0.07,2.28,1.66,3.02,2.81,3.51c0.25,0.11,0.5,0.21,0.73,0.33c15.89,8.36,26.67,21.48,32.04,38.98
      c2.21,7.2,2.29,14.78,2.2,21.33c-0.02,1.74-0.54,1.96-2.58,1.96h-0.11c-10.92-0.03-75.38-0.06-85.88,0.03
      c-1.33,0.01-2.13-0.17-2.54-0.58c-0.41-0.41-0.61-1.2-0.61-2.5c-0.06-26.56,11.53-46.48,34.44-59.21
      C99.3,113.13,99.54,113.02,99.78,112.91 M156.26,37.31c-24.97,0.05-95.58,0.02-95.58,0.02c-2.39,0-4.12,0-4.59-0.48
      c-0.47-0.47-0.46-2.17-0.46-4.52c0-1.16,0-2.16-0.02-3.05c-0.03-2.5-0.04-4.16,0.39-4.59c0.41-0.4,1.99-0.38,4.16-0.33
      c0.98,0.02,88.87,0.05,101.33-0.07c1.69,0.01,2.78,0.22,3.31,0.76c0.51,0.53,0.73,1.51,0.68,3.09c-0.05,1.63-0.05,3.01-0.06,4.17
      c-0.01,2.51-0.02,4.17-0.5,4.64c-0.49,0.48-2.24,0.45-4.83,0.4C159.09,37.33,156.36,37.31,156.26,37.31 M171.72,195.35
      c0.18-2.15-0.02-10.22-0.04-11.54c-0.08-5.54-1.96-7.62-7.44-8.24c-1.46-0.17-1.69-0.6-1.66-1.9c0.02-1.27,0.01-2.53-0.01-3.8
      c-0.38-25.16-11.2-45-32.18-58.95c-0.75-0.49-1.65-1.09-1.98-1.43c0.36-0.35,1.35-0.99,2.17-1.51
      c8.69-5.56,15.81-12.67,21.17-21.13c7.69-12.15,11.32-25.56,10.79-39.88c-0.06-1.63,0.1-2.48,0.54-2.96
      c0.41-0.44,1.22-0.68,2.45-0.71c3.64-0.11,5.98-2.73,6.1-6.83c0.08-2.85,0.07-5.7,0.06-8.55l-0.01-2.4c0-6.56-0.46-7.02-6.99-7.02
      l-98.28,0l-4.41,0.01c-2.67,0.01-5.34,0.02-8.01,0h-0.06c-1.56,0-2.7,0.39-3.49,1.18c-0.78,0.79-1.15,1.91-1.12,3.43
      c0.04,1.92,0.02,3.84,0.02,5.76c-0.01,2.23-0.02,4.46,0.03,6.68c0.12,4.89,2.33,7.32,7.14,7.87c1.75,0.2,2.03,0.77,2.01,2.06
      c-0.02,1.27-0.02,2.54,0.01,3.81c0.64,25.06,11.32,44.73,31.87,58.55c1.1,0.74,2.24,1.51,2.39,1.56c-0.15,0.3-1.3,1.04-2.44,1.78
      c-11.91,7.75-20.77,18.3-26.34,31.37c-3.76,8.82-5.56,18.56-5.52,29.76l0.01,0.59c0.04,1.78,0.06,2.52-1.87,2.69
      c-4.92,0.42-7.16,2.93-7.29,8.15c-0.08,3.43-0.05,6.86-0.03,10.3l0,0.66c0.04,4.97,0.95,5.84,6.07,5.84
      c0,0,103.37-0.03,110.31,0.08c0.11,0,0.22,0,0.33,0c1.59,0,3.38-0.28,4.61-1.44C171.91,197.99,171.72,195.35,171.72,195.35"/>
        <path fill="#89C7CF" d="M143.62,167.35c0.12,0.12,0.24,0.25,0.41,0.33C143.86,167.6,143.74,167.47,143.62,167.35 M144.94,167.88
      c-0.2,0-0.34-0.07-0.51-0.11c0.21,0.05,0.4,0.12,0.67,0.11C145.05,167.87,144.99,167.88,144.94,167.88 M142.88,165.41
      c0.02,0.32,0.06,0.64,0.14,0.93C142.95,166.04,142.9,165.73,142.88,165.41 M143.13,166.59c0.07,0.19,0.14,0.38,0.25,0.54
      C143.27,166.98,143.2,166.78,143.13,166.59 M110.76,125.67c1.16,0.01,2.33,0.13,3.5,0.4C113.08,125.8,111.92,125.67,110.76,125.67
       M114.32,126.09c1.19,0.28,2.4,0.69,3.62,1.25C116.72,126.78,115.52,126.37,114.32,126.09 M103.19,127.35
      c1.27-0.55,2.53-0.97,3.76-1.25C105.71,126.37,104.46,126.79,103.19,127.35 M75.09,166.67c0.1,0.08,0.18,0.18,0.3,0.24
      C75.27,166.85,75.19,166.76,75.09,166.67 M76.15,167.07c-0.16,0-0.27-0.05-0.41-0.08c0.15,0.03,0.28,0.09,0.45,0.08
      C76.18,167.07,76.16,167.07,76.15,167.07 M74.41,165.35c0.03,0.16,0.06,0.33,0.11,0.48C74.47,165.69,74.45,165.52,74.41,165.35
       M74.66,166.11c0.06,0.13,0.11,0.27,0.2,0.37C74.77,166.37,74.72,166.24,74.66,166.11 M74.35,165.03
      c-0.02-0.16-0.03-0.34-0.04-0.51C74.32,164.69,74.32,164.87,74.35,165.03 M74.32,161.5c0.01,0.49-0.02,1.09-0.03,1.7
      C74.3,162.59,74.33,161.99,74.32,161.5 M74.28,163.55c0,0.19-0.01,0.39,0,0.58C74.28,163.94,74.28,163.75,74.28,163.55
       M109.37,121.13c-0.84,0.06-1.69,0.19-2.55,0.47C107.68,121.31,108.53,121.19,109.37,121.13 M110.64,125.66
      c-1.21,0-2.41,0.14-3.63,0.42C108.23,125.8,109.44,125.66,110.64,125.66 M114.79,121.83c-0.68-0.2-1.37-0.37-2.06-0.5
      C113.43,121.46,114.11,121.63,114.79,121.83 M117.78,122.84c-0.77-0.29-1.54-0.56-2.32-0.81
      C116.23,122.28,117,122.55,117.78,122.84 M112.3,121.24c-0.81-0.13-1.63-0.18-2.45-0.15c0.12,0,0.25-0.03,0.37-0.03
      C110.92,121.06,111.61,121.13,112.3,121.24 M147.75,163.34c-0.01-0.37-0.02-0.79-0.02-1.48c0.09-17.74-11.26-33.05-29.61-39.94
      c-2.48-0.93-5.13-1.84-7.91-1.84c-1.29,0-2.5,0.19-3.7,0.58c-22.18,7.19-33.66,21.33-33.18,40.87c0.01,0.33-0.01,0.71-0.02,1.12
      c-0.06,1.67-0.12,3.56,0.94,4.66c0.48,0.49,1.13,0.75,1.89,0.75c1.15-0.02,1.95-0.32,2.51-0.91c0.96-1.01,0.91-2.56,0.86-4.35
      c-0.01-0.42-0.03-0.87-0.03-1.33c-0.59-15.07,7.29-25.92,24.09-33.21c2.49-1.08,4.81-1.61,7.09-1.61c2.27,0,4.51,0.52,6.86,1.59
      c14.74,6.72,22.61,16.9,24.05,31.11c0.15,1.51,0.18,2.44,0.2,3.5c0.02,0.74,0.04,1.54,0.1,2.62c0.19,3.07,2.19,3.4,3.05,3.4h0.03
      l0.19-0.01c2.5-0.13,2.56-2.36,2.6-3.44C147.78,164.35,147.77,163.91,147.75,163.34"/>
        <circle className="hourglass-image-sand hourglass-image-sand__left" fill="#89C7CF" cx="90" cy="94" r="2">
          <animate attributeType="CSS" attributeName="cy" from="94" to="100" dur="1s" repeatCount="indefinite" begin="0.25s" />
          <animate attributeType="CSS" attributeName="cx" from="90" to="96" dur="1s" repeatCount="indefinite" begin="0.25s" />
        </circle>
        <circle className="hourglass-image-sand hourglass-image-sand__left" fill="#89C7CF" cx="95" cy="99" r="2">
          <animate attributeType="CSS" attributeName="cy" from="99" to="105" dur="1s" repeatCount="indefinite" begin="0s" />
          <animate attributeType="CSS" attributeName="cx" from="95" to="101" dur="1s" repeatCount="indefinite" begin="0s" />
        </circle>
        <circle className="hourglass-image-sand hourglass-image-sand__center" fill="#89C7CF" cx="110" cy="105" r="2">
          <animate attributeType="CSS" attributeName="cy" from="100" to="120" dur="1.2s" repeatCount="indefinite" begin="0.5s" />
        </circle>
        <circle className="hourglass-image-sand hourglass-image-sand__center" fill="#89C7CF" cx="110" cy="115" r="2">
          <animate attributeType="CSS" attributeName="cy" from="105" to="125" dur="1.2s" repeatCount="indefinite" begin="0s" />
        </circle>
        <circle className="hourglass-image-sand hourglass-image-sand__right" fill="#89C7CF" cx="130" cy="94" r="2">
          <animate attributeType="CSS" attributeName="cy" from="94" to="100" dur="1s" repeatCount="indefinite" begin="0.25s" />
          <animate attributeType="CSS" attributeName="cx" from="130" to="124" dur="1s" repeatCount="indefinite" begin="0.25s" />
        </circle>
        <circle className="hourglass-image-sand hourglass-image-sand__right" fill="#89C7CF" cx="125" cy="99" r="2">
          <animate attributeType="CSS" attributeName="cy" from="99" to="105" dur="1s" repeatCount="indefinite" begin="0s" />
          <animate attributeType="CSS" attributeName="cx" from="125" to="119" dur="1s" repeatCount="indefinite" begin="0s" />
        </circle>
        <path fill="#89C7CF" d="M81.71,73.22c-0.19,0.06-0.39,0.16-0.6,0.3C81.33,73.37,81.52,73.28,81.71,73.22 M82.23,73.14
      c-0.11,0-0.22,0.02-0.34,0.04c0.1-0.02,0.21-0.05,0.3-0.05C82.21,73.13,82.22,73.14,82.23,73.14 M83.04,73.42
      c-0.05-0.03-0.09-0.06-0.14-0.09C82.94,73.36,82.99,73.39,83.04,73.42 M83.6,73.91c-0.02-0.02-0.04-0.03-0.05-0.05
      C83.56,73.87,83.58,73.89,83.6,73.91 M84.63,75.32c-0.17-0.24-0.35-0.5-0.53-0.76C84.28,74.82,84.46,75.08,84.63,75.32
       M114.01,91.22c0.18-0.06,0.37-0.12,0.55-0.19C114.38,91.1,114.19,91.16,114.01,91.22 M112.05,91.76c0.14-0.03,0.28-0.07,0.43-0.1
      C112.34,91.69,112.2,91.74,112.05,91.76 M136.65,74.76c-0.18,0.28-0.36,0.56-0.53,0.79C136.29,75.32,136.47,75.04,136.65,74.76
       M137.2,73.99c-0.01,0.01-0.02,0.02-0.03,0.03C137.18,74.02,137.19,74.01,137.2,73.99 M137.89,73.39c-0.05,0.03-0.1,0.07-0.15,0.1
      C137.79,73.45,137.84,73.42,137.89,73.39 M138.98,73.23c-0.13-0.03-0.26-0.07-0.38-0.07c0.01,0,0.01-0.01,0.02-0.01
      C138.73,73.16,138.86,73.2,138.98,73.23 M139.18,73.27c0.21,0.08,0.43,0.2,0.68,0.39C139.62,73.48,139.39,73.35,139.18,73.27
       M109.75,91.9c0.11,0.01,0.23,0.02,0.34,0.03C109.98,91.92,109.87,91.9,109.75,91.9 M98.22,93.18c0.34,0.17,8.45,4.24,12.63,4.24
      c6.14,0,22.09-8.63,29.4-18.86c0.81-1.13,2.71-3.78,0.2-5.69c-0.61-0.47-1.23-0.7-1.84-0.7c-1.48,0-2.33,1.32-2.95,2.29
      c-0.12,0.18-0.23,0.36-0.35,0.52c-4.62,6.48-10.98,11.23-19.44,14.49l-0.51,0.2c-1.69,0.66-3.29,1.29-5.04,1.29
      c-0.82,0-1.62-0.14-2.43-0.42c-10.18-3.57-17.32-8.59-22.47-15.79l-0.26-0.38c-0.64-0.93-1.51-2.22-2.97-2.22
      c-0.54,0-1.09,0.19-1.63,0.55c-0.78,0.52-1.25,1.17-1.39,1.92c-0.27,1.37,0.6,2.65,1.3,3.68C84.63,84.44,90.44,89.31,98.22,93.18"
        />
      </g>
    </svg>
  )
}

export default TimeGlass
