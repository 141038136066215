import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {Hero, PageSections} from '../components';

const DefaultPage = ({page, plugincomponent}) => {
  useEffect(() => {
    if (page && page.seo && page.seo.title ) {
      document.title = page.seo.title;
    }
    return () => {}
  }, [page])

  // this is script for init NewBanking plugin
  useEffect(() => {
    let nbProcessContainer = document.getElementById('nb-process-app-container')
    console.log(nbProcessContainer);
    if (nbProcessContainer) {
      window.initNewbankingProcessApp()
    }
  })

  return (
    <>
      {
        page.pageBlocks.hero && page.pageBlocks.hero.backgroundImage &&
        page.pageBlocks.hero.backgroundImage.sourceUrl &&
        <Hero
          title={page.pageBlocks.hero.header}
          background={page.pageBlocks.hero.backgroundImage?.sourceUrl} />}
      {page.pageBlocks.sections && <PageSections sections={page.pageBlocks.sections} plugincomponent={plugincomponent}/>}
    </>
  )
}

DefaultPage.propTypes = {
  page: PropTypes.object.isRequired,
  plugincomponent: PropTypes.shape({
    position: PropTypes.number.isRequired,
    component: PropTypes.elementType.isRequired
  })
}

export default DefaultPage;
