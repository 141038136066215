import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {GET_POST} from '../../gql';
import {LoadingIndicator} from '../components';
import {getSiteClient} from '../hooks/UseSiteQuery';

const NewsPost = ({subsite}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  let { id } = useParams();

  const getPostWithSiteClient = async (site = 'TG', slug) => {
    const client = getSiteClient(site);
    return client.query({ query: GET_POST, client: client, variables: {stringId: slug} })
      .then((result) => {
        setData(result.data)
        //return result.data.post
      })
      .catch((error) => {
        console.error(error)
        setError(error)
        //return error
      })
  }

  useEffect(() => {
    //console.log('NEWSPOST', id)
    getPostWithSiteClient(subsite, id)
      .then(() => setLoading(false))
  }, [id, subsite])

  if (error) {
    return <div><h1>Error: {id}</h1><pre>{error}</pre></div>
  }

  return (
    <div className="post md:px-28 px-8 md:py-20 py-10">
      {loading && <div className="full-page-spinner"><LoadingIndicator /></div>}
      {data && data.post?.content && <div dangerouslySetInnerHTML={{__html: data.post.content}}/>}
    </div>
  )
}

NewsPost.propTypes = {
  subsite: PropTypes.string
}

export default NewsPost
