import React from 'react';
import PropTypes from 'prop-types';

const FooterContact = ({data}) => {
  return (
    <div className="footer__contact grid md:grid-cols-4 grid-cols-1 lg:px-36 md:px-20 px-8">
      {data.telephone && <a href={`tel:${data.telephone}`} className="md:mb-0 mb-6 footer__sub-item">{data.telephone}</a>}
      {data.email && <a href={`mailto:${data.email}`} className="md:mb-0 mb-6 footer__sub-item">{data.email}</a>}
      {data.cvr && <p className="md:mb-0 mb-6 footer__sub-item">{data.cvr}</p>}
      <div className="flex md:mb-0 mb-10">
        {
          data.socialLinks && data.socialLinks.map((item, index) => (
            <a key={index} className="social-icon" href={item.link}>
              <img srcSet={item.icon.sourceUrl} alt={item.icon.altText || item.link}/>
            </a>
          ))
        }
      </div>
    </div>
  )
}

FooterContact.propTypes = {
  data: PropTypes.object.isRequired
}

export default FooterContact
