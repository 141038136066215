
import { gql } from '@apollo/client';
import {menus} from './menus';
import {themeOptions} from './themeoptions';

const defaultPages = `
    pages(first: 999) {
        nodes {
            uri
            title
            id
            slug
            content
            seo {
                title
            }
            parent {
                node {
                    slug
                }
            }
        }
    }
`;
const investPageSections = `
    pageSections {
      sections {
        ... on Page_Pagesections_Sections_Hero {
          fieldGroupName
          title
          backgroundImage {
            altText
            sourceUrl(size: LARGE)
            srcSet(size: LARGE)
          }
        }
        ... on Page_Pagesections_Sections_HeaderAndTextSection {
          fieldGroupName
          backgroundColor
          header
          text
          centerAlignText
        }
        ... on Page_Pagesections_Sections_OptionSelectorSection {
          fieldGroupName
          header
          numbered
          image {
            altText
            srcSet(size: LARGE)
            sourceUrl(size: LARGE)
          }
          options {
            option
            result
            backgroundColor
          }
          hourglass
        }
        ... on Page_Pagesections_Sections_WatermarkBackgroundSection {
          fieldGroupName
          text
          inlineImage {
            altText
            sourceUrl(size: LARGE)
            srcSet(size: LARGE)
          }
          backgroundColor
          watermarkImage {
            altText
            sourceUrl(size: LARGE)
            srcSet(size: LARGE)
          }
        }
        ... on Page_Pagesections_Sections_KnowledgeSliderSection {
          fieldGroupName
          fullWidth
          backgroundColor
          header
          items {
            content
            image {
                altText
                sourceUrl(size: LARGE)
                srcSet(size: LARGE)
              }
            showExpandButton
          }
          text
        }
        ... on Page_Pagesections_Sections_IconsSection {
          fieldGroupName
          iconGroup {
            header
            icon {
              altText
              sourceUrl(size: LARGE)
              srcSet(size: LARGE)
            }
            text
          }
        }
        ... on Page_Pagesections_Sections_ImageSection {
          fieldGroupName
          fullWidth
          maxWidth
          image {
            altText
            sourceUrl(size: LARGE)
            srcSet(size: LARGE)
          }
        }
        ... on Page_Pagesections_Sections_ColumnsSection {
          backgroundColor
          fieldGroupName
          header
          columns {
            content
          }
        }
        ... on Page_Pagesections_Sections_ImageTextColumns {
          fieldGroupName
          header
          text
          imageAlignment
          image {
            altText
            sourceUrl(size: LARGE)
            srcSet(size: LARGE)
          }
          readMore {
            label
            link {
              ... on Post {
                id
                uri
                link
              }
              ... on Page {
                id
                uri
                link
              }
            }
          }
        }
      }
    }
`;
const pageBlocks = `
    title
    seo {
        title
    }
    pageBlocks {
        hero {
            header
            backgroundImage {
                sourceUrl(size: LARGE)
                srcSet(size: LARGE)
            }
        }
        services {
            header
            text
            link
            image {
                sourceUrl(size: LARGE)
                srcSet(size: LARGE)
            }
        }
        sections {
            header
            background {
                color
                image {
                    altText
                    sourceUrl(size: LARGE)
                    srcSet(size: LARGE)
                }
                watermark {
                    altText
                    sourceUrl(size: LARGE)
                    srcSet(size: LARGE)
                }
            }
            columnsHeader
            columns {
                header
                icon {
                    altText
                    sourceUrl(size: LARGE)
                    srcSet(size: LARGE)
                }
                largeImage
                text
            }
            textColumns {
              text
              alignment
              linkButton {
                label
                link
                showButton
              }
            }
            imageColumns {
                imageLeft {
                    altText
                    sourceUrl(size: LARGE)
                    srcSet(size: LARGE)
                }
                imageRight {
                    altText
                    sourceUrl(size: LARGE)
                    srcSet(size: LARGE)
                }
            }
            showImageAndTextFields
            imageAndText {
                image {
                    altText
                    sourceUrl(size: LARGE)
                    srcSet(size: LARGE)
                }
                text
                isQuote
                imageAlignment
                arrowlinktext
                arrowlinkurl
            }
            videoAndText {
                headline
                text
                videoPlacementLeft
                vimeoVideo {
                    vimeoVideoId
                    vimeoVideoPoster {
                        altText
                        sourceUrl(size: LARGE)
                    }
                }
            }
            newsletter {
                showForm
                listId
                header
                text
                signupLabel
                consentText
                messages {
                    msgSubscribing
                    msgSuccess
                    msgInterests
                    msgConsent
                    msgEmailPlaceholder
                }
            }
            funds {
                header
                text
                backgroundImage {
                    altText
                    sourceUrl(size: LARGE)
                    srcSet(size: LARGE)
                }
            }
        }
    }
`;

const GET_PAGES = gql`
    query getPages {
        pages {
            ${defaultPages}
        }
    }
`;

const GET_SUBSITE_PAGES = gql`
    query getPages {
        ${defaultPages}
        ${menus}
        ${themeOptions}
    }
`;

const GET_PAGE = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            content
            seo {
                title
            }
            freshmail {
                showNewsletterForm
                listKey
                formFields {
                    consentText
                    emailLabel
                    header
                    nameLabel
                    signUpLabel
                    text
                    thankYouText
                }
            }
            ${pageBlocks}
        }
    }
`;

const GET_TIMEINVEST_PAGE_SECTIONS = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            title
            seo {
                title
            }
            ${investPageSections}
        }
    }
`;

const GET_TIMELAW_PAGE_SECTIONS = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            title
            seo {
                title
            }
            freshmail {
                showNewsletterForm
                listKey
                formFields {
                    consentText
                    emailLabel
                    header
                    nameLabel
                    signUpLabel
                    text
                    thankYouText
                }
            }
            
            pageContent {
                sections {
                    ... on Page_Pagecontent_Sections_Hero {
                        title
                        fieldGroupName
                        backgroundImage {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pagecontent_Sections_HeaderAndTextSection {
                        backgroundColor
                        fieldGroupName
                        header
                        text
                    }
                    ... on Page_Pagecontent_Sections_OptionSelectorSection {
                        fieldGroupName
                        header
                        numbered
                        options {
                            fieldGroupName
                            option
                            result
                        }
                        image {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pagecontent_Sections_WatermarkBackgroundSection {
                        backgroundColor
                        fieldGroupName
                        text
                        watermarkImage {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pagecontent_Sections_KnowledgeSliderSection {
                        backgroundColor
                        fieldGroupName
                        header
                        text
                        items {
                            content
                        }
                    }
                    ... on Page_Pagecontent_Sections_IconsSection {
                        fieldGroupName
                        iconGroup {
                            header
                            text
                            icon {
                                altText
                                sourceUrl(size: LARGE)
                                srcSet(size: LARGE)
                            }
                        }
                    }
                    ... on Page_Pagecontent_Sections_ImageSection {
                        fieldGroupName
                        image {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        fullWidth
                    }
                    ... on Page_Pagecontent_Sections_ImageTextColumns {
                        fieldGroupName
                        header
                        imageAlignment
                        image {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        text
                        readMore {
                          fieldGroupName
                          label
                          link {
                            ... on Page {
                              id
                              uri
                              link
                            }
                          }
                        }
                    }
                    ... on Page_Pagecontent_Sections_NewsletterSignup {
                        fieldGroupName
                        listId
                        header
                        text
                        signupLabel
                        consentText
                        messages {
                            msgSubscribing
                            msgSuccess
                            msgInterests
                            msgConsent
                            msgEmailPlaceholder
                        }
                    }
                    ... on Page_Pagecontent_Sections_ServicesGrid {
                        fieldGroupName
                        services {
                            backgroundImage {
                                altText
                                sourceUrl(size: LARGE)
                                srcSet(size: LARGE)
                            }
                            header
                            text
                            readMore {
                                label
                                textColor
                                link {
                                    ... on Page {
                                        id
                                        uri
                                        link
                                    }
                                    ... on Post {
                                        id
                                        uri
                                        link
                                    }
                                }
                            }
                        }
                    }
                    ... on Page_Pagecontent_Sections_TextColumnsModule {
                      fieldGroupName
                      textColumns {
                        alignment
                        fieldGroupName
                        linkButton {
                          fieldGroupName
                          label
                          link
                          showButton
                        }
                        text
                      }
                    }
                    ... on Page_Pagecontent_Sections_ImageColumns {
                        fieldGroupName
                        imageLeft {
                            altText
                            srcSet(size: LARGE)
                        }
                        imageRight {
                            altText
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pagecontent_Sections_VideoAndText {
                        fieldGroupName
                        headline
                        text
                        videoPlacementLeft
                        vimeoVideo {
                            fieldGroupName
                            vimeoVideoId
                            vimeoVideoPoster {
                                altText
                                sourceUrl
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_TIMERIGHT_PAGE_SECTIONS = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            title
            seo {
                title
            }
            freshmail {
                showNewsletterForm
                listKey
                formFields {
                    consentText
                    emailLabel
                    header
                    nameLabel
                    signUpLabel
                    text
                    thankYouText
                }
            }

            pageContent {
                sections {
                    ... on Page_Pagecontent_Sections_Hero {
                        title
                        fieldGroupName
                        backgroundImage {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pagecontent_Sections_HeaderAndTextSection {
                        backgroundColor
                        fieldGroupName
                        header
                        text
                    }
                    ... on Page_Pagecontent_Sections_OptionSelectorSection {
                        fieldGroupName
                        header
                        numbered
                        options {
                            fieldGroupName
                            option
                            result
                        }
                        image {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pagecontent_Sections_WatermarkBackgroundSection {
                        backgroundColor
                        fieldGroupName
                        text
                        watermarkImage {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pagecontent_Sections_KnowledgeSliderSection {
                        backgroundColor
                        fieldGroupName
                        header
                        text
                        items {
                            content
                        }
                    }
                    ... on Page_Pagecontent_Sections_IconsSection {
                        fieldGroupName
                        iconGroup {
                            header
                            text
                            icon {
                                altText
                                sourceUrl(size: LARGE)
                                srcSet(size: LARGE)
                            }
                        }
                    }
                    ... on Page_Pagecontent_Sections_ImageSection {
                        fieldGroupName
                        image {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        fullWidth
                    }
                    ... on Page_Pagecontent_Sections_ImageTextColumns {
                        fieldGroupName
                        header
                        imageAlignment
                        image {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        text
                        readMore {
                            fieldGroupName
                            label
                            link {
                                ... on Page {
                                    id
                                    uri
                                }
                            }
                        }
                    }
                    ... on Page_Pagecontent_Sections_NewsletterSignup {
                        fieldGroupName
                        listId
                        header
                        text
                        signupLabel
                        consentText
                        messages {
                            msgSubscribing
                            msgSuccess
                            msgInterests
                            msgConsent
                            msgEmailPlaceholder
                        }
                    }
                    ... on Page_Pagecontent_Sections_ServicesGrid {
                        fieldGroupName
                        services {
                            backgroundImage {
                                altText
                                sourceUrl(size: LARGE)
                                srcSet(size: LARGE)
                            }
                            header
                            text
                            readMore {
                                label
                                textColor
                                link {
                                    ... on Page {
                                        id
                                        uri
                                        link
                                    }
                                    ... on Post {
                                        id
                                        uri
                                        link
                                    }
                                }
                            }
                        }
                    }
                    ... on Page_Pagecontent_Sections_TextColumnsModule {
                        fieldGroupName
                        textColumns {
                            alignment
                            fieldGroupName
                            linkButton {
                                fieldGroupName
                                label
                                link
                                showButton
                            }
                            text
                        }
                    }
                    ... on Page_Pagecontent_Sections_ImageColumns {
                        fieldGroupName
                        imageLeft {
                            altText
                            srcSet(size: LARGE)
                        }
                        imageRight {
                            altText
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pagecontent_Sections_VideoAndText {
                        fieldGroupName
                        headline
                        text
                        videoPlacementLeft
                        vimeoVideo {
                            fieldGroupName
                            vimeoVideoId
                            vimeoVideoPoster {
                                altText
                                sourceUrl
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_TIMEVISION_PAGE_SECTIONS = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            title
            seo {
                title
            }
            freshmail {
                showNewsletterForm
                listKey
                formFields {
                    consentText
                    emailLabel
                    header
                    nameLabel
                    signUpLabel
                    text
                    thankYouText
                }
            }
            pageSections {
                sections {
                    ... on Page_Pagesections_Sections_Hero {
                        title
                        fieldGroupName
                        backgroundImage {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pagesections_Sections_HeaderAndTextSection {
                        backgroundColor
                        fieldGroupName
                        header
                        text
                    }
                    ... on Page_Pagesections_Sections_OptionSelectorSection {
                        fieldGroupName
                        header
                        numbered
                        options {
                            fieldGroupName
                            option
                            result
                        }
                        image {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pagesections_Sections_WatermarkBackgroundSection {
                        backgroundColor
                        fieldGroupName
                        text
                        watermarkImage {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pagesections_Sections_KnowledgeSliderSection {
                        backgroundColor
                        fieldGroupName
                        header
                        text
                        items {
                            content
                        }
                    }
                    ... on Page_Pagesections_Sections_IconsSection {
                        fieldGroupName
                        iconGroup {
                            header
                            text
                            icon {
                                altText
                                sourceUrl(size: LARGE)
                                srcSet(size: LARGE)
                            }
                        }
                    }
                    ... on Page_Pagesections_Sections_ImageSection {
                        fieldGroupName
                        image {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        fullWidth
                    }
                    ... on Page_Pagesections_Sections_ImageTextColumns {
                        fieldGroupName
                        header
                        imageAlignment
                        image {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        text
                        
                    }
                    ... on Page_Pagesections_Sections_NewsletterSignup {
                        fieldGroupName
                        listId
                        header
                        text
                        signupLabel
                        consentText
                        messages {
                            msgSubscribing
                            msgSuccess
                            msgInterests
                            msgConsent
                            msgEmailPlaceholder
                        }
                    }
                    ... on Page_Pagesections_Sections_ServicesGrid {
                        fieldGroupName
                        services {
                            backgroundImage {
                                altText
                                sourceUrl(size: LARGE)
                                srcSet(size: LARGE)
                            }
                            header
                            text
                            readMore {
                                label
                                textColor
                                link {
                                    ... on Page {
                                        id
                                        uri
                                        link
                                    }
                                    ... on Post {
                                        id
                                        uri
                                        link
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_F_P_PENSIONS_PAGE = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            title
            ${pageBlocks}
            pagePension {
                columns {
                    overlayColor
                    content
                    backgroundImage {
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
            }
        }
    }
`;

const GET_F_P_INSURANCES_PAGE = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            title
            ${pageBlocks}
            pageBusinessInsurance {
                popularInsurancesGrid {
                    header
                    popularInsurances {
                        icon {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        header
                        content
                    }
                }
                insurancesGrid {
                    header
                    insurances {
                        icon {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        header
                        expandButton {
                            offLabel
                            onLabel
                        }
                        content
                    }
                }
                headerAndTextSection {
                    header
                    text
                    button {
                        label
                        link
                    }
                }
            }
        }
    }
`;

const GET_TIMEINVEST_OVERVIEW_PAGE = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            sections {
                ... on Page_Pageoverview_Sections_TextSection {
                    header
                    text
                }
                ... on Page_Pageoverview_Sections_BackgroundSection {
                    backgroundColor
                    header
                    text
                    watermark {
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
                ... on Page_Pageoverview_Sections_ImageAndText {
                    header
                    image {
                        altText
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                    text
                    readmore {
                        label
                        link {
                            ... on Page {
                                id
                                uri
                            }
                        }
                    }
                }
                ... on Page_Pageoverview_Sections_NewsletterSignUp {
                    fieldGroupName
                    listId
                    header
                    text
                    signupLabel
                    consentText
                    messages {
                        msgSubscribing
                        msgSuccess
                        msgInterests
                        msgConsent
                        msgEmailPlaceholder
                    }
                }
            }
        }
    }
`;

const GET_TIMEINVEST_ABOUT_PAGE = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            title
            seo {
                title
            }
            pageAbout {
                hero {
                    header
                    backgroundImage {
                        altText
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
                aboutSections {
                    ... on Page_Pageabout_AboutSections_HeaderAndText {
                        header
                        text
                        readMoreUrl {
                            linkUrl
                            label
                        }
                    }
                    ... on Page_Pageabout_AboutSections_ImageColumns {
                        fieldGroupName
                        leftImage {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        rightImage {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pageabout_AboutSections_ImageAndQuote {
                        imageAlignment
                        quoteText
                        image {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                    }
                    ... on Page_Pageabout_AboutSections_ImageAndText {
                        imageAlignment
                        text
                        image {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        readMore {
                            label
                            link {
                                ... on Page {
                                    id
                                    uri
                                    link
                                }
                                ... on MediaItem {
                                    id
                                    uri
                                    link
                                }
                                ... on Post {
                                    id
                                    uri
                                    link
                                }
                            }
                        }
                    }
                    ... on Page_Pageabout_AboutSections_BackgroundSection {
                        backgroundColor
                        header
                        text
                    }
                    ... on Page_Pageabout_AboutSections_IconsSection {
                        header
                        iconGroup {
                            header
                            icon {
                                altText
                                sourceUrl(size: LARGE)
                                srcSet(size: LARGE)
                            }
                        }
                    }
                    ... on Page_Pageabout_AboutSections_ColumnsList {
                        backgroudColor
                        header
                        columns {
                            header
                            text
                        }
                    }
                }
            }
        }
    }
`;

const GET_TIMEINVEST_KNOWLEDGE_PAGE = gql`

    query getPage($stringId: ID!) {
        page(id: $stringId) {
            title
            seo {
                title
            }
            pageKnowledgeCenter {
                hero {
                    header
                    image {
                        altText
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
                investmentPrinciplesSection {
                    backgroundColor
                    header
                    text
                    slider {
                        content
                    }
                }
                blogsSection {
                    header
                    text
                    button {
                        label
                        link {
                            ... on Page {
                                id
                                uri
                                link
                                slug
                            }
                        }
                    }
                    posts {
                        post {
                            ... on Post {
                                id
                                date
                                featuredImage {
                                    node {
                                        altText
                                        sourceUrl(size: LARGE)
                                        srcSet(size: LARGE)
                                    }
                                }
                                title
                                excerpt
                                newsPost {
                                    dateLabel
                                    external {
                                        externalArticle
                                        externalLink
                                    }
                                }
                                content
                                categories {
                                    nodes {
                                        name
                                    }
                                }
                                uri
                                slug
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_ABOUT_PAGE = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            title
            seo {
                title
            }
            pageBlocks {
                hero {
                    header
                    backgroundImage {
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
            }
            pageAbout {
                history {
                    header
                    columns {
                        column
                    }
                }
                imageColumns {
                    imageLeft {
                        altText
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                    imageRight {
                        altText
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
                timeline {
                    backgroundColor
                    header
                    years {
                        year
                        text
                    }
                }
                strategy {
                    header
                    text
                }
                mission {
                    header
                    text
                    image {
                        altText
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
                vision {
                    header
                    text
                    image {
                        altText
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
                values {
                    header
                    subheader
                    columns {
                        header
                        text
                        icon {
                            altText
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                    }
                }
                backgroundSection {
                    backgroundColor
                    header
                    text
                }
                
            }
        }
    }
`;

const GET_CAREERS_PAGE = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            ${pageBlocks}
            pageCareer {
                image {
                    altText
                    sourceUrl(size: LARGE)
                    srcSet(size: LARGE)
                }
                unsolicited {
                    header
                    text
                    button {
                        link
                        label
                    }
                }
                trainee {
                    header
                    text
                    button {
                        label
                        link
                    }
                }
            }
        }
    }
`;

const GET_CONTACT_PAGE = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            title
            ${pageBlocks}
            pageContact {
                formImage {
                    altText
                    sourceUrl(size: LARGE)
                    srcSet(size: LARGE)
                }
                formHeader
                formSubmitButton
                addresses {
                    mapImage {
                        altText
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                    header
                    address
                    phone
                    email
                }
                formSubheader
                hidePressContactSection
                pressContactSection {
                    header
                    subheader
                    contactName
                    contactTitle
                    contactPhone
                    contactEmail
                    contactFooter
                    watermark {
                        altText
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
            }
        }
    }
`;

export {
  GET_CAREERS_PAGE,
  GET_PAGE,
  GET_PAGES,
  GET_SUBSITE_PAGES,
  GET_CONTACT_PAGE,
  GET_ABOUT_PAGE,
  defaultPages,
  pageBlocks,
  GET_TIMEINVEST_OVERVIEW_PAGE,
  GET_TIMEINVEST_ABOUT_PAGE,
  GET_TIMEINVEST_PAGE_SECTIONS,
  GET_TIMEINVEST_KNOWLEDGE_PAGE,
  GET_F_P_INSURANCES_PAGE,
  GET_F_P_PENSIONS_PAGE,
  GET_TIMEVISION_PAGE_SECTIONS,
  GET_TIMELAW_PAGE_SECTIONS,
  GET_TIMERIGHT_PAGE_SECTIONS
};
