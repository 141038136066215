import gql from 'graphql-tag';

const themeOptions = `
themeOptions {
    headerContent {
      goToTimegruppenLink
      getStartedButton
    }
    footerCTA {
      footerCta {
        backgroundColor
        backgroundImage {
          altText
          sourceUrl(size: LARGE)
          srcSet(size: LARGE)
        }
        button {
          label
          link {
            ... on Page {
              id
              uri
              slug
            }
          }
        }
        title
      }
    }
    footerCustomers {
      showCustomers
      customers {
        image {
          sourceUrl
        }
      }
    }
    footerMenus {
      subsiteMenus {
        title {
            link
            text
        }
        menuItems {
          menuItem {
            label
            link
          }
        }
      }
      topMenu {
        header
        items {
          group {
            label
            link
          }
        }
      }
      timegruppenMenu {
        menuItem {
          label
          link {
            ... on Page {
              id
              uri
            }
          }
        }
      }
    }
    footerContact {
      telephone
      email
      cvr
      socialLinks {
        icon {
          altText
          sourceUrl(size: LARGE)
          srcSet(size: LARGE)
        }
        link
      }
      privacy {
        ... on Page {
          title
          uri
          link
        }
      }
    }
    footerCopyright {
      copyright
    }
    notfoundPage {
      title
      text
      menu {
        label
        link {
          ... on Page {
            id
            uri
          }
        }
      }
    }
    gdprFormInfo {
      label
    }
    getStarted {
      header
      email
      formSubmitButton
      formHeader
    }
  }
`;

const GET_THEMEOPTIONS = gql`
    query getThemeOptions {
        ${themeOptions}
    }
`;

export {GET_THEMEOPTIONS, themeOptions}
