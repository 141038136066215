import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import useWindowSize from '../hooks/WindowResize';
import SlideArrow from '../../assets/images/SlideArrow';

const InvestKnowledgeSlider = ({data}) => {
  const [isExpanded, setIsExpanded] = useState(-1)
  const size = useWindowSize();
  const slider = useRef()
  const overlay = useRef()

  useEffect(() => {
    const sliderWidth = slider.current.scrollWidth
    slider.current.scroll({ left: ((sliderWidth - window.innerWidth) / 2) })

    slider.current.onscroll = () => {
      overlay.current.style.left = slider.current.scrollLeft+'px'
    }
  }, [])

  useEffect(() => {
    if (slider.current) {
      let slides = slider.current.querySelectorAll('.slide');

      slides.forEach((slide) => {
        let content = slide.querySelectorAll('.slide__content');
        let ps = content[0].querySelectorAll('p')
        let found = false;

        ps.forEach((p) => {
          if (p.offsetTop > 380 && !found) {
            const spacer = document.createElement('div')
            spacer.classList.add('spacer');
            p.after(spacer)
            found = true;
          }
        })
      });
    }

  }, [slider])

  const scrollSlider = (side) => {
    const slideWidth = size.width > 767 ? 0.6 * size.width : 0.95 * size.width
    if (side === 'left') {
      if (slider.current.scrollLeft > 295) {
        slider.current.scrollBy({
          left: -1 * slideWidth,
          behavior: 'smooth'
        })
      } else if (slider.current.scrollLeft > 0) {
        slider.current.scrollBy({
          left: (-1 * slider.current.scrollLeft),
          behavior: 'smooth'
        })
      }
    } else if (side === 'right') {
      if (slider.current.scrollLeft < (slider.current.scrollWidth - 295)) {
        slider.current.scrollBy({
          left: slideWidth,
          behavior: 'smooth'
        })
      } else if (slider.current.scrollLeft < slider.current.scrollWidth) {
        slider.current.scrollBy({
          left: slider.current.scrollWidth,
          behavior: 'smooth'
        })
      }
    }
  }

  return (
    <div className="knowledge-slider">
      {data.header && <h2 className="text-center mb-4">{data.header}</h2>}
      <div className="grid md:grid-cols-3 grid-cols-1">
        <div />
        {data.text && (
          <div className="flex justify-center">
            <div className="max-w-screen-sm" dangerouslySetInnerHTML={{__html: data.text}}/>
          </div>
        )}
        <div className="flex justify-center" style={{ alignItems: 'flex-end' }}>
          <div className="grid-slider__buttons-container no-hide mt-4 md:mt-0" style={{ width: '136px' }}>
            <div className="grid-slider__button grid-slider__button--back grid-slider__button--dark" onClick={() => scrollSlider('left')}><SlideArrow color="#2a3a60" /></div>
            <div className="grid-slider__button grid-slider__button--next grid-slider__button--dark" onClick={() => scrollSlider('right')}><SlideArrow color="#2a3a60" /></div>
          </div>
        </div>
      </div>
      <div ref={slider} style={{ gridTemplateColumns: `repeat(${data.items?.length}, ${size.width > 768 ? '843px' : '311px'})` }}
        className={`relative slides-container md:mt-11 mt-6 overflow-x-scroll grid gap-8 grid-cols-${data.items?.length}`}>
        {/* <div className="offset-overlay absolute w-full h-full" ref={overlay} />
        <div /> */}
        {
          data.items?.map((item, index) => (
            <div id={`slide-${index}`}
              className={
                   `slide py-12 px-4 md:px-12 grid md:grid-cols-2 grid-cols-1 
                    ${index === 0 ? 'md:ml-8' : index === data.items.length-1 ? 'md:mr-8' : ''}
                   ${isExpanded === index ? 'expanded' : ''}`
              }
              key={index}>
              <div>
                <div
                  className={`slide__content ${isExpanded === index ? 'expanded' : ''} ${item.showExpandButton ? 'show-expand' : ''} `}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
                {
                  item.showExpandButton && (
                    <div
                      className="slide-expand flex items-center"
                      onClick={() => {
                        if (isExpanded === index) {
                          setIsExpanded(-1)
                        } else {
                          setIsExpanded(index)
                        }
                      }}>
                      <span>{isExpanded === index ? 'Læs mindre' : 'Læs mere'}</span>
                      <div className={`caret ${isExpanded === index ? 'active' : ''}`} />
                    </div>
                  )
                }
              </div>
              <img srcSet={item.image.srcSet} alt={item.image.altText} className="slide__image" />
            </div>
          ))
        }
        <div />
      </div>
    </div>
  )
}

InvestKnowledgeSlider.propTypes = {
  data: PropTypes.object.isRequired
}

export default InvestKnowledgeSlider
