import {GET_MENUS} from './menus';
import {
  GET_PAGES,
  GET_PAGE,
  GET_CONTACT_PAGE,
  GET_ABOUT_PAGE,
  GET_SUBSITE_PAGES,
  GET_CAREERS_PAGE,
  GET_TIMEINVEST_ABOUT_PAGE,
  GET_TIMEINVEST_PAGE_SECTIONS,
  GET_TIMEINVEST_KNOWLEDGE_PAGE,
  GET_F_P_INSURANCES_PAGE,
  GET_F_P_PENSIONS_PAGE,
  GET_TIMEVISION_PAGE_SECTIONS,
  GET_TIMELAW_PAGE_SECTIONS,
  GET_TIMERIGHT_PAGE_SECTIONS
} from './pages';
import {GET_INITIAL_CONTENT} from './default';
import {SEND_EMAIL, GET_STARTED} from './sendMail';
import {GET_THEMEOPTIONS} from './themeoptions';
import {GET_EMPLOYEES} from './employees';
import {GET_NEWS_PAGE, GET_POSTS_PAGINATED, GET_POST, GET_POSTS} from './posts';
import {GET_TIMEINVEST_FUNDS_PAGE} from './timeinvest';

export {
  GET_INITIAL_CONTENT,
  GET_MENUS,
  GET_PAGES,
  GET_SUBSITE_PAGES,
  GET_CAREERS_PAGE,
  GET_PAGE,
  GET_CONTACT_PAGE,
  GET_ABOUT_PAGE,
  SEND_EMAIL,
  GET_STARTED,
  GET_THEMEOPTIONS,
  GET_EMPLOYEES,
  GET_NEWS_PAGE,
  GET_POSTS_PAGINATED,
  GET_POST,
  GET_TIMEINVEST_FUNDS_PAGE,
  GET_TIMEINVEST_ABOUT_PAGE,
  GET_TIMEINVEST_PAGE_SECTIONS,
  GET_TIMEINVEST_KNOWLEDGE_PAGE,
  GET_POSTS,
  GET_F_P_INSURANCES_PAGE,
  GET_F_P_PENSIONS_PAGE,
  GET_TIMEVISION_PAGE_SECTIONS,
  GET_TIMELAW_PAGE_SECTIONS,
  GET_TIMERIGHT_PAGE_SECTIONS
}
