import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const LegalDocuments = ({page}) => {
  useEffect(() => {
    if (page && page.title) {
      document.title = page.title;
    }
    return () => {}
  }, [page])

  return (
    <div className="py-16">
      {
        page.pageBlocks?.hero?.header
          ? <div dangerouslySetInnerHTML={{__html: page.pageBlocks.hero.header}}/>
          : <h1>{page.title}</h1>
      }
      {
        page.pageBlocks.services && (
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-8 gap-4 md:mt-32 mt-6 simple-grid">
            {
              page.pageBlocks.services.map((service, index) => (
                <a
                  href={service.link}
                  target="_blank" rel="noreferrer"
                  key={index}
                  className={'px-4 py-6 hover:translate-y-2 simple-grid__item'}>
                  <h3 className="mb-6">{service.header}</h3>
                  <p dangerouslySetInnerHTML={{ __html: service.text }} />
                </a>
              ))
            }
          </div>
        )
      }
    </div>
  )
}

LegalDocuments.propTypes = {
  page: PropTypes.object.isRequired
}

export default LegalDocuments
