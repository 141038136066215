import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const InsuranceSections = ({data}) => {
  const {insurancesGrid, headerAndTextSection, popularInsurancesGrid} = data
  const [activeGridItem, setActiveGridItem] = useState(-1)

  //console.log(insurancesGrid, headerAndTextSection, popularInsurancesGrid)

  return (
    <>
      {
        popularInsurancesGrid &&
          <div className="sections__insurances-grid md:mt-32 mt-10"
            data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
            <div dangerouslySetInnerHTML={{ __html: popularInsurancesGrid.header }} />
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-40 md:gap-24 gap-6 md:mt-12 mt-6">
              {
                popularInsurancesGrid.popularInsurances.map((insurance, index) => {
                  return (
                    <div key={index} className="insurance-grid-item">
                      <div className="flex">
                        <img className="insurance-grid-item__image" srcSet={insurance.icon?.sourceUrl} alt={insurance.icon?.altText} />
                        <h4>{insurance.header}</h4>
                      </div>
                      <div className="insurance-grid-item__content" dangerouslySetInnerHTML={{ __html: insurance.content }} />
                    </div>
                  )
                })
              }
            </div>
          </div>
      }
      {
        insurancesGrid &&
          <div className="sections__insurances-grid md:mt-32 mt-10"
            data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
            <div dangerouslySetInnerHTML={{ __html: insurancesGrid.header }} />
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-40 md:gap-24 gap-6 md:mt-12 mt-6">
              {
                insurancesGrid.insurances.map((insurance, index) => {
                  return (
                    <div key={index} className="insurance-grid-item">
                      <div className="flex">
                        <img className="insurance-grid-item__image" srcSet={insurance.icon?.sourceUrl} alt={insurance.icon?.altText} />
                        <h4>{insurance.header}</h4>
                      </div>
                      <div className="insurance-grid-item__expand-button cursor-pointer flex items-center"
                        onClick={() => {
                          if (activeGridItem === index) setActiveGridItem(-1)
                          else setActiveGridItem(index)
                        }}>
                        <span className="md:mr-2 mr-1">
                          {
                            activeGridItem === index ? insurance.expandButton?.offLabel : insurance.expandButton?.onLabel
                          }
                        </span>
                        <div className={`caret ease-out transition-all duration-300 ${activeGridItem === index ? 'active' : ''}`} />
                      </div>
                      <div className={`insurance-grid-item__content insurance-grid-item__content--toggles ${activeGridItem === index ? 'show' : 'hide'}`}
                        dangerouslySetInnerHTML={{ __html: insurance.content }} />
                    </div>
                  )
                })
              }
            </div>
          </div>
      }
      {
        headerAndTextSection &&
          <div data-aos="fade-up" data-aos-once="true" data-aos-offset="10"
            className="sections__section md:mt-32 mt-16 relative" style={{ zIndex: -1 }}>
            {
              headerAndTextSection.header &&
              <div className="sections__header md:mb-10 mb-4" dangerouslySetInnerHTML={{__html: headerAndTextSection.header}}/>
            }
            {
              headerAndTextSection.text &&
              <div className="sections__section__textColumns length-1"
                dangerouslySetInnerHTML={{ __html: headerAndTextSection.text }} />
            }
            {
              headerAndTextSection.button &&
                <div className="md:mt-11 mt-6">
                  <Button
                    label={headerAndTextSection.button.label}
                    cb={() => window.location = headerAndTextSection.button.link}/>
                </div>
            }
          </div>
      }
    </>
  )
}

InsuranceSections.propTypes = {
  data: PropTypes.object.isRequired
}

export default InsuranceSections
