import { gql } from '@apollo/client';
import {pageBlocks} from './pages';

const GET_TIMEINVEST_FUNDS_PAGE = gql`
    query getPage($stringId: ID!) {
        page(id: $stringId) {
            ${pageBlocks}
            pageUdvalgAfFonde {
                funds {
                    header
                    text
                    backgroundImage {
                        altText
                        sourceUrl(size: LARGE)
                        srcSet(size: LARGE)
                    }
                }
            }
        }
    }
`;

export {
  GET_TIMEINVEST_FUNDS_PAGE
}
