import React from 'react';
import PropTypes from 'prop-types';

const Button = ({label, cb, type = 'normal', classes = [], isSubmit, disabled}) => {
  classes.push('button', type, 'ease-in-out transition-all duration-200');
  if (type === 'normal' || type === 'block') {
    classes.push('rounded-3xl', 'py-3');
  }
  return (
    <button disabled={disabled} type={isSubmit ? 'submit' : ''} className={classes.join(' ')}  onClick={() => cb()}>{label}</button>
  );
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  cb: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['small', 'large', 'block', 'normal']),
  classes: PropTypes.array,
  isSubmit: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Button;
