import React from 'react';
import PropTypes from 'prop-types';
import {ReadMore} from '../index';

const ServicesGrid = ({section}) => {
  return (
    <div className="sections__section__services
                                md:mt-32 mt-16
                                grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1
                                md:gap-x-8 md:gap-y-16 gap-x-4 gap-y-4">
      {
        section.services.map((service, index) => {
          const overLayStyle = {}
          if (service.backgroundColor) overLayStyle.backgroundColor = service.backgroundColor
          const style = {}
          if (service.backgroundImage) style.backgroundImage = `url(${service.backgroundImage.sourceUrl})`
          return (
            <div key={index} className={`service ${!service.backgroundImage ? 'no-bg' : ''}`} style={style}>
              <div className="service__text-container md:py-12 md:px-6 py-8 px-4">
                {service.header && <div className="md:mb-6 service__header" dangerouslySetInnerHTML={{__html: service.header}}/>}
                {service.text && <div className="service__text" dangerouslySetInnerHTML={{__html: service.text}}/>}
                {
                  service.readMore &&
                                    <div className="absolute md:left-6 left-4 md:bottom-8 bottom-8">
                                      <ReadMore label={service.readMore.label} link={service.readMore.link.link} color={service.readMore.textColor} />
                                    </div>
                }
              </div>
              <div className="service__overlay" />
            </div>
          )
        })
      }
    </div>
  );
}

ServicesGrid.propTypes = {
  section: PropTypes.any,
};

export default ServicesGrid;


