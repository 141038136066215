import React from 'react';
import PropTypes from 'prop-types';
import {LoadingIndicator} from './index';

const AppShell = ({loading}) => {
  return (
    <>
      <div className="placeholder-routes xl:-mx-36 md:-mx-16 -mx-8 flex items-center">
        {loading && <div className="full-page-spinner" ><LoadingIndicator/></div>}
      </div>
    </>
  )
}

AppShell.propTypes = {
  loading: PropTypes.bool,
  hidenav: PropTypes.bool
}

export default AppShell
