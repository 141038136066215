import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useMutation} from '@apollo/client';
import {CheckBoxes} from '../components/GetStarted/';
import {Form} from '../components';
import {GET_STARTED, GET_THEMEOPTIONS} from '../../gql';
import {useSiteClient} from '../hooks/UseSiteQuery';

const GetStarted = ({area}) => {
  const client = useSiteClient()
  const [data, setQueryObject] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [formStatus, setFormStatus] = useState(null)
  const [sendingStatus, setSendingStatus] = useState(false)
  const [areas, setAreas] = useState([])
  const defaultState = [
    {name: 'firstName', element: 'input', type: 'text', value: '', placeholder: 'Fornavn'},
    {name: 'lastName', element: 'input', type: 'text', value: '', placeholder: 'Efternavn'},
    {name: 'company', element: 'input', type: 'text', value: '', placeholder: 'Virksomhed'},
    {name: 'email', element: 'input', type: 'email', value: '', placeholder: 'Email'},
    {name: 'telephone', element: 'input', type: 'tel', value: '', placeholder: 'Telefonnummer'},
  ]
  const [formFields, setFormFields] = useState([
    {name: 'firstName', element: 'input', type: 'text', value: '', placeholder: 'Fornavn'},
    {name: 'lastName', element: 'input', type: 'text', value: '', placeholder: 'Efternavn'},
    {name: 'company', element: 'input', type: 'text', value: '', placeholder: 'Virksomhed'},
    {name: 'email', element: 'input', type: 'email', value: '', placeholder: 'Email'},
    {name: 'telephone', element: 'input', type: 'tel', value: '', placeholder: 'Telefonnummer'},
  ])
  const [sendEmail] = useMutation(GET_STARTED)

  useEffect(() => {
    if (client && !data) {
      client.query({ query: GET_THEMEOPTIONS, client: client })
        .then((result) => {
          setLoading(false)
          setQueryObject(result.data.themeOptions)

          setTimeout(() => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          })
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [client])


  useEffect(() => {
    document.title = 'Kom i gang | '  + area;

    if (area === 'timeinvest') {
      setAreas(['Investering'])
    }
    if (area === 'timelaw') {
      setAreas(['Jura'])
    }
    if (area === 'timeright') {
      setAreas(['GDPR'])
    }
    if (area === 'revision') {
      setAreas(['Revision'])
    }
  }, [])

  function areasSelected(selected) {
    setAreas(selected)
  }

  function getCheckBoxes() {
    if (area === 'timegruppen' || area === 'forsikring-og-pension') {
      return (
        <CheckBoxes area={area} callback={(response) => areasSelected(response)}/>
      )
    } else {
      return null
    }
  }

  function getToEmail(area) {
    let email;

    switch (area) {
      case 'timeinvest':
        email = 'timeinvest@time.dk,cff@time.dk,cba@time.dk'
        break;
      case 'revision':
        email = 'timevision@time.dk,cff@time.dk'
        break;
      case 'forsikring-og-pension':
        email = 'time@soderbergpartners.dk,cff@time.dk'
        break;
      case 'timelaw':
        email = 'hgc@timelaw.dk,cff@time.dk'
        break;
      case 'GDPR':
        email = 'mle@timeright.dk,cff@time.dk'
        break;
      default:
        email = 'timegruppen@time.dk,cff@time.dk';
    }
    return email;
  }

  function submitForm() {

    if (sendingStatus) return

    const body = `
            Name: ${formFields[0].value} ${formFields[1].value}
            
            Company: ${formFields[2].value}
            
            Email: ${formFields[3].value}
            
            Telephone: ${formFields[4].value}
            
            Areas of interest:
            
            ${areas.map(businessArea => businessArea+' ')}
            `;
    const toEmail = getToEmail(area);
    // set sending status flag
    setSendingStatus(true)
    sendEmail({variables: {to: toEmail, subject: `${window.location.href} Henvendelse fra websitet`, body}})
      .then((res) => {
        //console.log(res)
        if (res.data.sendEmail.sent) {
          setFormStatus({type: 'success', message: 'Besked afsendt'})

          setFormFields(defaultState)
        } else {
          setFormStatus({type: 'error', message: res.data.sendEmail.message})
        }


      })
      .catch((err) => {
        //console.log(err)
        setFormStatus({type: 'error', message: err.message})
      })
      .finally(() => {
        setSendingStatus(false)
      })
  }

  return (
    <div className="get-started">
      {!loading && data && <h1 className="mb-10 text-center md:mb-16">{data.getStarted?.header}</h1>}
      {getCheckBoxes()}
      {!loading && data &&
        <h2 className={`text-center form-container-header md:mt-32 mt-20 mb-8 ${areas.length > 0 ? 'active' : ''}`}>
          {data.getStarted.formHeader}
        </h2>
      }
      <div className={`form-container px-8 md:pt-20 pt-10 md:pb-9 pb-10  ${areas.length > 0 ? 'active' : ''}`}>
        {!loading && data &&
          <Form extraClasses="flex flex-col center-button" defaultState={defaultState} sendingStatus={sendingStatus} gridColumns="2" rowGap="16" columnGap="8" status={formStatus}
            formFields={formFields} callbackLabel={data.getStarted.formSubmitButton} callback={(response) => {
              setFormFields(response)
              submitForm()
            }}/>
        }
      </div>
    </div>
  )
}

GetStarted.propTypes = {
  area: PropTypes.string.isRequired,
  //client: PropTypes.any
}

export default GetStarted
