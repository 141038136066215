import React from 'react';
import PropTypes from 'prop-types';
import ReadMore from './ReadMore';
import {Button} from './index';
import VideoAndText from './VideoAndText/VideoAndText';
import MailchimpForm from './MailchimpForm';

function lightOrDark(color) {

  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  }
  else {

    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp>127.5) {

    return 'light';
  }
  else {

    return 'dark';
  }
}

const PageSections = ({sections, plugincomponent}) => {
  return (
    <>
      {
        sections.map((section, index) => {
          let bgStyle = {};
          let bgClass = '';
          let textColorClass = '';
          if (section.background.color) {
            const bgColorStyle = lightOrDark(section.background.color)
            bgStyle['backgroundColor'] = section.background.color
            bgClass = `bg-color ${bgColorStyle} md:py-32 py-10 lg:px-32 md:px-16 px-6`
            textColorClass = 'sections__section__white-text';
          }
          if (section.background.image) {
            bgStyle['backgroundImage'] = `url(${section.background.image.sourceUrl})`
            bgClass = 'bg-image'
          }
          if (section.background.watermark) {
            bgStyle['backgroundImage'] = `url(${section.background.watermark.sourceUrl})`
            bgClass = 'bg-image bg-image--watermark'
          }
          return (
            <React.Fragment key={`section_${index}`}>
              {plugincomponent && plugincomponent.position === index && plugincomponent.component}
              <div data-aos="fade-up" data-aos-once="true" data-aos-offset="10" style={bgStyle} key={index} className={`sections__section md:mt-32 mt-16 ${bgClass} ${textColorClass}`}>
                {section.header && <div className="sections__header" dangerouslySetInnerHTML={{__html: section.header}}/>}
                {
                  section.textColumns && (
                    <div className={`
                    sections__section__textColumns 
                    length-${section.textColumns.length} 
                    grid grid-cols-1 md:grid-cols-${section.textColumns.length} gap-10 
                    md:mt-16 mt-6
                  `}>
                      {section.textColumns.map((column, index) => (
                        <React.Fragment key={index}>
                          <p className={`${column.alignment === 'center: Center' ? 'text-center' : ''}`} key={index} dangerouslySetInnerHTML={{ __html: column.text.replace(/\n/g,'<br />')}} />
                          {
                            column.linkButton.label && column.linkButton.link && (
                              <>
                                {
                                  column.linkButton.showButton
                                    ? <Button label={column.linkButton.label} cb={() => window.open(column.linkButton.link, '_blank').focus()} />
                                    : <ReadMore label={column.linkButton.label} link={column.linkButton.link} external={true} />
                                }
                              </>
                            )
                          }
                        </React.Fragment>
                      )
                      )}
                    </div>
                  )
                }
                {
                  section.imageColumns && section.imageColumns.imageLeft && section.imageColumns.imageRight && (
                    <div className="sections__section__imageColumns grid md:grid-cols-3 grid-cols-1 gap-32 md:mb-32">
                      <img
                        className="image-left"
                        srcSet={section.imageColumns.imageLeft.srcSet}
                        alt={section.imageColumns.imageLeft.altText} />
                      <img
                        className="image-right md:col-span-2"
                        srcSet={section.imageColumns.imageRight.srcSet}
                        alt={section.imageColumns.imageRight.altText} />
                    </div>
                  )
                }
                {
                  section.columnsHeader && (<div className="mt-14" dangerouslySetInnerHTML={{ __html: section.columnsHeader }} />)
                }
                {
                  section.columns && (
                    <div className={`sections__section__columns grid grid-cols-1 md:grid-cols-${section.columns.length} md:gap-20 gap-10 md:mt-12 mt-10`}>
                      {section.columns.map((column, index) => (
                        <div key={index} className="flex flex-col items-center px-0">
                          <img
                            style={{
                              width: column.largeImage ? '100%' : '60px',
                              height: column.largeImage ? '272px' : '60px',
                              objectFit: 'cover',
                            }}
                            width={column.largeImage ? '100%' : 60}
                            height={column.largeImage ? 272 : 60}
                            src={column.icon?.sourceUrl} alt={column.icon?.altText || 'column.text'}
                          />
                          {column.header && <h4 className="text-center my-4">{column.header}</h4>}
                          <p className="text-center mt-4" dangerouslySetInnerHTML={{ __html: column.text.replace(/\n/g,'<br />') }} />
                        </div>
                      ))}
                    </div>
                  )
                }
                {
                  section.imageAndText.image && section.imageAndText.text && (
                    <div
                      className={
                      `sections__section__image-and-text 
                      flex 
                      flex-col 
                      ${section.imageAndText.imageAlignment === 'right' ? 'md:flex-row-reverse' : 'md:flex-row'}`
                      }>
                      <img
                        className="mb-6 md:mb-0 object-cover cover-image"
                        srcSet={section.imageAndText.image?.srcSet} alt={section.imageAndText.image?.altText} />
                      <div>
                        <div
                          className={`text text--${section.imageAndText.imageAlignment} ${section.imageAndText.isQuote && 'isQuote'}`}
                          dangerouslySetInnerHTML={{ __html: section.imageAndText.text }} />
                        {
                          section.imageAndText.arrowlinktext && section.imageAndText.arrowlinkurl &&
                          <div className={`text--${section.imageAndText.imageAlignment}`}>
                            <ReadMore label={section.imageAndText.arrowlinktext} link={section.imageAndText.arrowlinkurl}/>
                          </div>
                        }
                      </div>
                    </div>
                  )
                }
                {
                  section.videoAndText && section.videoAndText.vimeoVideo.vimeoVideoId && (
                    <VideoAndText
                      text={section.videoAndText.text}
                      videoPlacementLeft={section.videoAndText.videoPlacementLeft}
                      headline={section.videoAndText.headline}
                      vimeoVideo={section.videoAndText.vimeoVideo} />
                  )
                }
                {
                  section.newsletter && section.newsletter.showForm && (
                    <MailchimpForm data={ section.newsletter }/>
                  )
                }
                {
                  section.funds && (
                    <div className="sm:overflow-x-scroll grid lg:grid-cols-2 gap-8 md:mt-32 mt-20 investment-funds">
                      {                 
                        section.funds.map((fund, index) => {
                          if(fund.header == null || fund.text == null || fund.backgroundImage.sourceUrl == null) {
                            return;
                          }
                          return (
                              <div
                                key={index}
                                style={{ backgroundImage: `url(${fund.backgroundImage.sourceUrl})` }}
                                className="investment-funds__fund bg-no-repeat bg-cover sm:mr-4">
                                <div className="fund-text-container py-10 md:py-12 lg:px-16 md:px-12 px-6">
                                  <h2 className="fund-header mb-6">{fund.header}</h2>
                                  <div className="fund-text" dangerouslySetInnerHTML={{ __html: fund.text }} />
                                </div>
                              </div>
                          )
                        })
                    }
                    </div>
                  )
                }
              </div>
            </ React.Fragment>
          )
        })
      }
    </>
  )
}

PageSections.propTypes = {
  sections: PropTypes.array.isRequired,
  plugincomponent: PropTypes.shape({
    position: PropTypes.number.isRequired,
    component: PropTypes.elementType.isRequired
  })
}

export default PageSections;
