import React from 'react';
import PropTypes from 'prop-types';
import {InvestKnowledgeSlider} from '../index';

const KnowledgeSliderSection = ({section}) => {
  return (
    <div
      style={{
        backgroundColor: section.backgroundColor
      }}
      className={`sections__section bg-color md:mt-32 mt-16 md:py-32 py-10 ${section.fullWidth ? 'xl:-mx-36 md:-mx-16 -mx-8' : ''}`}
      data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
      <InvestKnowledgeSlider data={section} />
    </div>
  );
}

KnowledgeSliderSection.propTypes = {
  section: PropTypes.any,
};

export default KnowledgeSliderSection;

