import React, {useState} from 'react';
import PropTypes from 'prop-types';

const Input = ({name, element, type, value, placeholder, label, callback, validation, checked, onChange, required, extraClasses}) => {
  const [inValid, setInvalid] = useState(false)
  if (element === 'input') {
    return (
      <div className={`form-element-wrapper form-element-wrapper__input form-element-wrapper__input--${type}${validation ? ' error' : ''} ${extraClasses}`}>
        <input
          required={required}
          onInvalid={() => setInvalid(true)}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={(e) => callback(e.target.value)}
        />
        {validation && <p className={inValid ? 'show' : 'hide'}>{validation}</p>}
      </div>
    )
  } else if (element === 'textarea') {
    return (
      <div className={`form-element-wrapper form-element-wrapper__textarea${validation ? ' error' : ''} ${extraClasses}`}>
        <textarea
          required={required}
          onInvalid={() => setInvalid(true)}
          rows="1"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(e) => callback(e.target.value)}
        />
        {validation && <p className={inValid ? 'show' : 'hide'}>{validation}</p>}
      </div>
    )
  } else if (element === 'checkbox') {
    return (
      <div className={`form-element-wrapper form-element-wrapper__checkbox${validation ? ' error' : ''} ${extraClasses}`}
        onClick={() => {
          //callback(true)
        }}>
        <label htmlFor={name} className="flex">
          <div className={`checkbox-pseudo ${checked ? 'checked' : ''}`} />
          <input id={name} name={name} required={required} type="checkbox" value={value} checked={checked} onChange={onChange} />
          <span>{label}</span>
        </label>
      </div>
    )
  } else {
    return null
  }
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  element: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  label: PropTypes.any,
  callback: PropTypes.func,
  validation: PropTypes.string,
  required: PropTypes.bool,
  extraClasses: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

export default Input;
