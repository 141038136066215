import React, {useContext, useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Hero, Button, ReadMore} from '../../components';
import SlideArrow from '../../../assets/images/SlideArrow';
import useWindowSize from '../../hooks/WindowResize';
import SiteContext from '../../context/SiteContext';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

const KnowledgeCenter = ({page}) => {
  useEffect(() => {
    if (page && page.seo && page.seo.title ) {
      document.title = page.seo.title;
    }
    return () => {}
  }, [page])

  const { currentSiteData } = useContext(SiteContext)
  const history = useHistory()
  const data = page.pageKnowledgeCenter
  const wrapper = useRef();
  const slider = useRef();
  const gridPosts = useRef();
  const {width} = useWindowSize()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [expand, setExpand] = useState(false)

  useEffect(() => {
    if (gridPosts) {
      setHeightForTitles(gridPosts);
    }
  }, [gridPosts])

  useEffect(() => {
    if (width && gridPosts) {
      setHeightForTitles(gridPosts);
    }
  }, [width])

  const setHeightForTitles = (gridPosts) => {
    const posts = gridPosts.current.querySelectorAll('.grid-post')

    let heighest = 10;

    posts.forEach((post) => {
      const title = post.querySelectorAll('.grid-post__header')
      title[0].style.height = 'auto';
    });

    posts.forEach((post) => {
      const title = post.querySelectorAll('.grid-post__header')
      const height = title[0].getBoundingClientRect().height;

      if (height && height > heighest) {
        heighest = height;
      }
    });

    posts.forEach((post) => {
      const title = post.querySelectorAll('.grid-post__header')
      title[0].style.height = `${heighest}px`;
    });
  }

  function getCurrentSlide() {
    for (let i = 0; i < data.investmentPrinciplesSection.slider.length; i++) {
      const {left} = document.getElementById(`slide-${i}`).getBoundingClientRect()
      if (left < width && left > 0) {
        return i
      }
    }
  }

  const scrollSlider = (side) => {
    const scrollOptions = {
      behavior: 'smooth'
    }
    if (slider.current.getBoundingClientRect().top > 5) {
      scrollOptions.block = 'end'
    }
    const currentSlideIndex = getCurrentSlide()
    if (currentSlideIndex === 0 && side === 'left') {
      return
    }
    if (currentSlideIndex === data.investmentPrinciplesSection.slider.length - 1 && side === 'right') {
      return
    }
    const goToSlideIndex = side === 'right' ? currentSlideIndex + 1 : currentSlideIndex - 1
    const scrollLengthAdjustment = width > 1279 ? 0.05 * width : 32
    const scrollLength = document.getElementById(`slide-${goToSlideIndex}`).getBoundingClientRect().left - scrollLengthAdjustment
    if (side === 'left') {
      if (currentSlide > 0) {
        const previousSlide = currentSlide - 1
        slider.current.scrollBy({
          left: scrollLength,
          behavior: 'smooth'
        })
        setCurrentSlide(previousSlide)
      }
    } else if (side === 'right') {
      if (currentSlide < data.investmentPrinciplesSection.slider.length - 1) {
        const nextSlide = currentSlide + 1
        slider.current.scrollBy({
          left: scrollLength,
          behavior: 'smooth'
        })
        setCurrentSlide(nextSlide)
      }
    }
  }

  return (
    <>
      <Hero
        title={data.hero.header}
        background={data.hero.image.sourceUrl} />
      {
        data.investmentPrinciplesSection && (
          <div className="xl:-mx-36 md:-mx-16 -mx-8 sections__section md:py-32 py-6 md:px-20 px-8 py-6 md:mt-28 mt-6 knowledge__principles"
            style={{backgroundColor: data.investmentPrinciplesSection.backgroundColor}} ref={ wrapper }>
            <div className="flex md:flex-row flex-col justify-between md:mb-16 mb-6">
              <div>
                <h2 className="mb-4">{data.investmentPrinciplesSection.header}</h2>
                <div dangerouslySetInnerHTML={{ __html: data.investmentPrinciplesSection.text }} />
              </div>
              <div className="grid-slider__buttons-container no-hide">
                <div className="grid-slider__button grid-slider__button--back"
                  onClick={() => scrollSlider('left')}><SlideArrow color="#ffffff" /></div>
                <div className="grid-slider__button grid-slider__button--next"
                  onClick={() => scrollSlider('right')}><SlideArrow color="#ffffff" /></div>
              </div>
            </div>
            <div
              style={{
                gridTemplateColumns: `repeat(
                  ${data.investmentPrinciplesSection.slider.length}, 
                  ${width > 1535 ? width * 0.9 
                  : width > 1279 ? width * 0.89 
                    : width > 767 ? width * 0.9 
                      : width - (4 * 16)}px
                )`
              }}
              className={'grid-slider grid gap-6 overflow-x-scroll'}
              ref={slider}>
              {
                data.investmentPrinciplesSection.slider.map((slide, index) => {
                  return (
                    <div id={`slide-${index}`} className="slider__slide md:py-10 py-6 md:px-24 px-8" key={index}>
                      <div className={`content ${expand ? 'active' : ''}`}
                        dangerouslySetInnerHTML={{ __html: slide.content }} />
                      <div className="flex md:hidden items-center absolute read-more"
                        onClick={() => {
                          setExpand(!expand)

                          if (expand) {
                            gsap.to(window, {
                              duration: .2, scrollTo: wrapper.current
                            });
                          }
                        }}>
                        <p className="mr-1.5">{expand ? 'Læs mindre' : 'Læs mere'}</p>
                        <div className={`caret ${expand ? 'active' : ''}`} />
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      }
      {
        data.blogsSection && (
          <div className="md:py-32 py-10 flex flex-col items-center">
            <h2 className="text-center md:mb-6 mb-4">{data.blogsSection.header}</h2>
            <div className="md:mb-6 mb-4" dangerouslySetInnerHTML={{ __html: data.blogsSection.text }} />
            <Button label={data.blogsSection.button.label}
              cb={() => history.push(`${currentSiteData.baseUrl}${data.blogsSection.button.link.slug}`)} />
            <div className="grid md:grid-cols-3 grid-cols-1 md:gap-8 gap-4 md:mt-20 mt-10 w-full" ref={ gridPosts }>
              {
                data.blogsSection.posts.map(({post}, index) => {
                  // const date = post.newsPost?.dateLabel ? post.newsPost.dateLabel : moment(post.date).format('Do MMMM YYYY')
                  const date = post.newsPost?.dateLabel ? post.newsPost.dateLabel : null
                  return (
                    <div key={index} className="grid-post flex flex-col">
                      {/* <div className="grid-post__image h-2/4" */}
                      <div className="grid-post__image"
                        style={
                          post.featuredImage ? {backgroundImage: `url(${post.featuredImage.node.sourceUrl})`} : {backgroundColor: '#7f9fd2'}
                        }/>
                      <div className="grid-post__content px-4 md:px-6 flex flex-col flex-grow pb-4 md:pb-6">
                        {/* <div className="grid-post__content h-2/4 py-4 md:py-6 px-4 md:px-6 flex flex-col justify-between"> */}
                        <p className="grid-post__date py-2 md:py-4">{date || ''}</p>
                        <p className="grid-post__header">{post.title}</p>
                        <div className="grid-post__text mb-4" dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                        <div className="mt-auto">
                          <ReadMore label="Læs mere" link={currentSiteData.baseUrlClean + post.uri} color="#f7f7f7" />
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      }
    </>
  )
}

KnowledgeCenter.propTypes = {
  page: PropTypes.object.isRequired
}

export default KnowledgeCenter
