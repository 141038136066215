import React from 'react';
import Shield from '../../assets/images/LogoMarkLight.svg';
import {
  FORSIKRING_OG_PENSION_TEXT,
  FORSIKRING_OG_PENSION_URL,
  INVEST_TEXT,
  INVEST_URL, REVISION_TEXT,
  REVISION_URL,
  TIMELAW_URL,
  TIMELAW_TEXT,
  TIMERIGHT_URL,
  TIMERIGHT_TEXT,

} from '../constants';

const Error = () => {
  return (
    <div className="md:pt-36 pt-16 md:px-20 px-8 error-indicator" style={{
      backgroundImage: `url(${Shield})`
    }}>
      <h1 className="mb-6">Der er sket en fejl</h1>
      <div className="services grid sm:grid-cols-2 md:grid-cols-4 grid-cols-1">
        <a
          href={REVISION_URL}
          className={'services__service md:mb-0 mb-10 px-4 py-6'}>
          <h2>Revision & Skat</h2>
          <div className="header__separator" />
          <p>{REVISION_TEXT}</p>
        </a>
        <a
          href={FORSIKRING_OG_PENSION_URL}
          className={'services__service md:mb-0 mb-10 px-4 py-6'}>
          <h2>Forsikring & Pension</h2>
          <div className="header__separator" />
          <p>{FORSIKRING_OG_PENSION_TEXT}</p>
        </a>
        <a
          href={INVEST_URL}
          className={'services__service md:mb-0 mb-10 px-4 py-6'}>
          <h2>Investering</h2>
          <div className="header__separator" />
          <p>{INVEST_TEXT}</p>
        </a>
        <a
          href={TIMELAW_URL}
          className={'services__service md:mb-0 mb-10 px-4 py-6'}>
          <h2>Jura</h2>
          <div className="header__separator" />
          <p>{TIMELAW_TEXT}</p>
        </a>
        <a
          href={TIMERIGHT_URL}
          className={'services__service md:mb-0 mb-10 px-4 py-6'}>
          <h2>Timeright</h2>
          <div className="header__separator" />
          <p>{TIMERIGHT_TEXT}</p>
        </a>
      </div>
    </div>
  )
}

export default Error
