import gql from 'graphql-tag';
import {menus} from './menus';
import {defaultPages} from './pages';

const GET_INITIAL_CONTENT = gql`
    query getInitialContent {
        ${menus}
        ${defaultPages}
    }
`;

export { GET_INITIAL_CONTENT }
