import gql from 'graphql-tag';

const menus = `
    menus {
        nodes {
            name
            menuItems(first: 99) {
                nodes {
                    path
                    label
                    childItems {
                        nodes {
                            path
                            label
                            order
                        }
                    }
                    parentId
                }
            }
        }
    }
`;

const GET_MENUS = gql`
    query getMenus {
        ${menus}
        themeOptions {
            headerContent {
                goToTimegruppenLink
                getStartedButton
            }
        }
    }
`;

export { GET_MENUS, menus };
