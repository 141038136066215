import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {Hero, OptionsSelector, MailchimpForm} from '../../components';
import HeaderAndTextSection from '../../components/HeaderAndTextSection/HeaderAndTextSection';
import ImageSection from '../../components/ImageSection/ImageSection';
import WatermarkBackgroundSection from '../../components/WatermarkBackgroundSection/WatermarkBackgroundSection';
import KnowledgeSliderSection from '../../components/KnowledgeSliderSection/KnowledgeSliderSection';
import IconsSection from '../../components/IconsSection/IconsSection';
import ColumnsSection from '../../components/ColumnsSection/ColumnsSection';
import ImageTextColumns from '../../components/ImageTextColumns/ImageTextColumns';
import ServicesGrid from '../../components/ServicesGrid/ServicesGrid';
import TextColumns from '../../components/TextColumns/TextColumns';
import ImageColumns from '../../components/ImageColumns/ImageColumns';
import VideoAndText from '../../components/VideoAndText/VideoAndText';

const TimelawDefault = ({page}) => {
  useEffect(() => {
    if (page && page.seo && page.seo.title ) {
      document.title = page.seo.title;
    }
    return () => {}
  }, [page])

  if (page.pageContent?.sections.length === 0) return null

  return (
    <>
      {
        page.pageContent?.sections?.map((section, key) => {

          switch(section.fieldGroupName) {
            case 'page_Pagecontent_Sections_Hero':
              return <Hero
                title={section.title}
                background={section.backgroundImage?.sourceUrl} key={key}/>
            case 'page_Pagecontent_Sections_HeaderAndTextSection':
              return (
                <HeaderAndTextSection section={section} key={key}/>
              )
            case 'page_Pagecontent_Sections_ImageSection':
              return (
                <ImageSection section={section} key={key}/>
              )
            case 'page_Pagecontent_Sections_OptionSelectorSection':
              return <OptionsSelector data={section} type={section.hourglass ? 'hourglass' : 'backgroundImage'} key={key}/>
            case 'page_Pagecontent_Sections_WatermarkBackgroundSection':
              return (
                <WatermarkBackgroundSection section={section} key={key}/>
              )
            case 'page_Pagecontent_Sections_KnowledgeSliderSection':
              return (
                <KnowledgeSliderSection section={section} key={key}/>
              )
            case 'page_Pagecontent_Sections_IconsSection':
              return (
                <IconsSection section={section} key={key}/>
              )
            case 'page_Pagecontent_Sections_ColumnsSection':
              return (<ColumnsSection section={section} key={key}/>)

            case 'page_Pagecontent_Sections_ImageTextColumns':
              return (
                <ImageTextColumns section={section} key={key}/>
              )
            case 'page_Pagecontent_Sections_ServicesGrid':
              return (
                <ServicesGrid section={section} key={key}/>
              )
            case 'page_Pagecontent_Sections_TextColumnsModule':
              return (
                <TextColumns section={section} key={key}/>
              )
            case 'page_Pagecontent_Sections_ImageColumns':
              return (
                <ImageColumns section={section} key={key}/>
              )
            case 'page_Pagecontent_Sections_VideoAndText':
              return (
                <div className={'sections__section md:mt-32 mt-16'} data-aos="fade-up" data-aos-once="true" data-aos-offset="10" key={key}>
                  <VideoAndText
                    text={section.text}
                    videoPlacementLeft={section.videoPlacementLeft}
                    headline={section.headline}
                    vimeoVideo={section.vimeoVideo} />
                </div>
              )
            case 'page_Pagecontent_Sections_NewsletterSignup':
              return (
                <div className={`sections__section__newsletter 
                    md:mt-32 mt-16 flex flex-col`} data-aos="fade-up" data-aos-once="true" data-aos-offset="10" key={key}>
                  <MailchimpForm data={section}/>
                </div>
              )
          }
        })
      }
    </>
  )
}

TimelawDefault.propTypes = {
  page: PropTypes.object.isRequired
}

export default TimelawDefault
