import React, { useEffect, useState} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Aos from 'aos';
import {GET_INITIAL_CONTENT} from '../gql';
import {AppContainer, AppShell} from './components/';
import Routes from './Router';
import 'aos/dist/aos.css';
import { SiteProvider } from './context/SiteContext';
import { useSiteClient } from './hooks/UseSiteQuery';
const devMode = process.env.NODE_ENV === 'development';
import TagManager from 'react-gtm-module';
import ScrollToTop from './components/ScrollToTop';

// Google Tag Manager Id
const tagManagerArgs = {
  gtmId: 'GTM-5FZSKBD'
}

const AppRoutes = () => {
  const client = useSiteClient()
  const [data, setQueryObject] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(undefined)

  useEffect(() => {
    if (client) {
      client.query({ query: GET_INITIAL_CONTENT, client: client })
        .then((result) => {
          setQueryObject(result.data)
          setLoading(false)
        })
        .catch((error) => {

          console.error(error)
          setError(error)
        })
    }
  }, [client])

  function renderRoutes() {
    if (!data && loading || error) {
      return <AppShell/>
    }

    return <Routes menus={data.menus} pages={data.pages.nodes} />
  }

  return(
    <>
      {renderRoutes()}
    </>
  )
}

const App = () => {

  // initialize Google Tag Manager only on first render
  useEffect(() => {

    // only do this in prod
    if (!devMode) {
      TagManager.initialize(tagManagerArgs)
    }
  }, [])




  useEffect(()=> {
    Aos.init()
  }, [])


  return (
    <Router>
      <ScrollToTop>
        <SiteProvider>
          <AppContainer>
            <AppRoutes />
          </AppContainer>
        </SiteProvider>
      </ScrollToTop>
    </Router>
  );
}

export default App;
