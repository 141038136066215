import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { useSiteClient } from '../hooks/UseSiteQuery';
import {GET_THEMEOPTIONS} from '../../gql';
import Shield from '../../assets/images/LogoMarkLight.svg';
import {LoadingIndicator} from '../components';

const NotFound = () => {
  const client = useSiteClient()
  const [data, setQueryObject] = useState(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (client) {
      client.query({ query: GET_THEMEOPTIONS, client: client })
        .then((result) => {
          setLoading(false)
          setQueryObject(result.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [client])

  if (!data) return null

  return (
    <div className="md:pt-36 pt-16 md:px-20 px-8 notfound" style={{
      backgroundImage: `url(${Shield})`
    }}>
      {
        loading && !data && <LoadingIndicator />
      }
      {
        data && !loading &&
          <>
            <h1 className="notfound__header mb-6">{data?.themeOptions.notfoundPage.title}</h1>
            <div className="text" dangerouslySetInnerHTML={{__html: data?.themeOptions.notfoundPage.text}} />
            <div className="flex menu">
              {
                data.themeOptions.notfoundPage.menu && data.themeOptions.notfoundPage.menu.map((item, index) => (
                  <Link key={index} to={item.link?.uri}>{item.label}</Link>
                ))
              }
            </div>
          </>
      }
    </div>
  )
}


export default NotFound
