import React, { useState, useLayoutEffect, useEffect, useContext } from 'react'; // eslint-disable-line no-unused-vars

import SiteContext from '../context/SiteContext';

import {onError} from '@apollo/client/link/error';
import {ApolloClient, ApolloLink, HttpLink} from '@apollo/client';
import {InMemoryCache} from 'apollo-cache-inmemory';

const devMode = process.env.NODE_ENV === 'development';

export const useSiteQuery = (gqlQuery) => {
  const queryClient = useSiteClient()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null)

  useEffect(() => {
    if (queryClient) {
      doQuery(gqlQuery)
    }
  }, [gqlQuery, queryClient])

  const doQuery = (query) => {
    return queryClient.query({query: query})
      .then((result) => {
        setData(result.data)
        return result;
      })
      .catch((error) => {
        setLoading(false)
        setError(error)
        return error;
      })
  }

  return {
    data,
    loading,
    error
  }
}

export function getSiteClient (siteName) {
  // custom error handling, only logging errors atm
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      // do something with graphql error
      console.error({ graphQLErrors });
    }

    if (networkError) {
      // do something with network error
      console.error({ networkError });
    }
  });

  let httpLink = undefined;

  if (siteName === 'TV') {
    httpLink = new HttpLink({
      uri: devMode
        ? 'https://staging.timegruppen.twentyfour.dev/revision-og-skat/graphql'
        : `https://${process.env.HOSTNAME}/revision-og-skat/graphql`
    });
  }

  if (siteName === 'TI') {
    httpLink = new HttpLink({
      uri: devMode
        ? 'https://staging.timegruppen.twentyfour.dev/timeinvest/graphql'
        : `https://${process.env.HOSTNAME}/timeinvest/graphql`
    });
  }

  if (siteName === 'TG') {
    httpLink = new HttpLink({
      uri: devMode
        ? 'https://staging.timegruppen.twentyfour.dev/graphql'
        : `https://${process.env.HOSTNAME}/graphql`
    });
  }

  if (siteName === 'FP') {
    httpLink = new HttpLink({
      uri: devMode
        ? 'https://staging.timegruppen.twentyfour.dev/forsikring-og-pension/graphql'
        : `https://${process.env.HOSTNAME}/forsikring-og-pension/graphql`
    });
  }

  if (siteName === 'TL') {
    httpLink = new HttpLink({
      uri: devMode
        ? 'https://staging.timegruppen.twentyfour.dev/timelaw/graphql'
        : `https://${process.env.HOSTNAME}/timelaw/graphql`
    });
  }

  if (siteName === 'TR') {
    httpLink = new HttpLink({
      uri: devMode
        ? 'https://staging.timegruppen.twentyfour.dev/timeright/graphql'
        : `https://${process.env.HOSTNAME}/timeright/graphql`
    });
  }

  if (!httpLink) return;

  const link = ApolloLink.from([
    errorLink,
    httpLink,
  ]);

  const cache = new InMemoryCache({
    introspectionQueryResultData: {
      __schema: {
        types: []
      }
    }
  });

  const client = new ApolloClient({
    cache,
    link,
    resolvers: {},
  });

  return client;
}

export function useSiteClient () {
  const { site } = useContext(SiteContext)
  const [queryClient, setClient] = useState(null);

  const setQueryClient = (site) => {
    const client = getSiteClient(site);

    setClient(client)
  }

  useLayoutEffect(() => {
    switch(site) {
      case 'TV' :
        // code block
        setQueryClient('TV')
        break;
      case 'FP' :
        // code block
        setQueryClient('FP')
        break;
      case 'TI' :
        //  code block
        setQueryClient('TI')
        break;
      case 'TG' :
        //  code block
        setQueryClient('TG')
        break;
      case 'TL' :
        //  code block
        setQueryClient('TL')
        break;
      case 'TR' :
        //  code block
        setQueryClient('TR')
        break;
    }

    return () => {}
  }, [site]);

  return queryClient;
}
