import React from 'react';
import PropTypes from 'prop-types';

const WatermarkBackgroundSection = ({section}) => {
  const style = {}
  if (section.watermarkImage) style.backgroundImage = `url(${section.watermarkImage.sourceUrl})`
  if (section.backgroundColor) style.backgroundColor = section.backgroundColor

  return (
    <div
      style={style}
      className={
                `sections__section 
                       md:mt-32 mt-16 
                       md:py-32 py-10 
                       lg:px-32 md:px-16 px-6 
                       bg-color 
                       ${section.watermarkImage ? 'bg-image bg-image--watermark flex items-center justify-center' : ''} 
                       ${section.inlineImage ? 'md:flex' : ''}`
      }
      data-aos="fade-up" data-aos-once="true" data-aos-offset="10">
      <div className="content" dangerouslySetInnerHTML={{__html: section.text}}/>
      {section.inlineImage && <img className="inline-image" srcSet={section.inlineImage.srcSet} alt={section.inlineImage.altText} />}
    </div>
  );
}

WatermarkBackgroundSection.propTypes = {
  section: PropTypes.any,
};

export default WatermarkBackgroundSection;
