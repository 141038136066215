import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

const FooterCustomers = ({ data }) => {

  useEffect(() => {
    const root = document.documentElement;
    const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue('--marquee-elements-displayed');
    const marqueeContent = document.querySelector('ul.marquee-content');

    root.style.setProperty('--marquee-elements', marqueeContent.children.length);

    for(let i=0; i<marqueeElementsDisplayed; i++) {
      marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
    }
  }, [])

  return (
    // <div className="footer-customers py-6 md:py-14 flex" ref={slider}>
    <div className="marquee py-6 md:py-14">
      <ul className="marquee-content">
        {
          data && data.map((item, key) => {
            return (
              <li key={ key }>
                <img src={item.image.sourceUrl} alt={item.image.altText} key={ key } className="footer-customers__item mx-2" />
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

FooterCustomers.propTypes = {
  data: PropTypes.array.isRequired
}

export default FooterCustomers;
