import React, {useState} from 'react';
import {withApollo} from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import Button from './Button';
import Input from './Input';

const axios = require('axios');

const NewsletterForm = ({ data }) => {
  const [formFields, setFormFields] = useState([
    {name: 'name', element: 'input', type: 'text', value: '', placeholder: data.formFields.nameLabel || 'Dit navn'},
    {name: 'email', element: 'input', type: 'email', value: '', placeholder: data.formFields.emailLabel || 'Din email'},
  ])
  const [consented, setConsented] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [confirmText, setConfirmText] = useState(data.formFields.thankYouText)

  async function submitForm() {
    if (consented) {
      const payload = {
        'email': formFields[1].value,
        'list': data.listKey,
        'state': 1,
        'custom_fields': {
          name: formFields[0].value,
          'confirm': consented ? 'yes' : 'no',
        },
      }

      const result = await axios.post(
        // 'http://freshmail.loc/index.php/wp-json/freshmail/v2/fresh',
        '/wp-json/freshmail/v2/fresh',
        payload
      )

      if (result) {
        if (result.data.status === 'ERROR') {
          setConfirmText(`<h2>${ result.data.errors[0].message }</h2>`);
          setFormSubmitted(true)
        }

        if (result.data.status === 'OK') {
          setFormSubmitted(true)
        }

      }
    }
  }

  if (formSubmitted) {
    return (
      <div style={{ backgroundColor: '#f7f7f7' }}
        className={
            `newsletter-form
              md:py-32 py-16 xl:px-36 md:px-16 px-8
              grid md:grid-cols-3 md:gap-20
              xl:-mx-36 md:-mx-16 -mx-8
              md:mt-32 mt-16`
        }>
        <div>
          <div dangerouslySetInnerHTML={{ __html: confirmText }} />
        </div>
      </div>
    )
  }

  return (
    <div style={{ backgroundColor: '#f7f7f7' }}
      className={
           `newsletter-form
            md:py-32 py-16 xl:px-36 md:px-16 px-8
            grid md:grid-cols-3 md:gap-20
            xl:-mx-36 md:-mx-16 -mx-8
            md:mt-32 mt-16`
      }>
      <div>
        <h2 className="mb-4">{data.formFields.header || 'Nyhedsmail sign-up'}</h2>
        <p>{data.formFields.text || 'Skriv dig op her. Så sender vi vores seneste nyheder.'}</p>
      </div>

      <div>
        {
          formFields.map((field, index) => (
            <Input
              key={index}
              extraClasses={`newsletter-form__input ${index === 0 ? 'mb-12 override-margin' : ''}`}
              required={true}
              element={field.element}
              name={field.name}
              type={field.type}
              value={field.value}
              placeholder={field.placeholder}
              callback={(inputResponse) => {
                const temp = [...formFields]
                temp[index].value = inputResponse
                setFormFields(temp)
              }}
            />
          ))
        }
      </div>

      <div>
        <Button classes={['mb-10']} label={data.formFields.signUpLabel || 'Skriv mig op'} cb={() => submitForm()}/>
        <Input
          onChange={(event) => {
            setConsented(event.target.checked)
          }}
          checked={consented}
          label={<div dangerouslySetInnerHTML={{ __html: data.formFields.consentText }} />}
          element="checkbox"
          name="consent" />
      </div>
    </div>
  )

}

NewsletterForm.propTypes = {
  data: PropTypes.any.isRequired,
}

export default withApollo(NewsletterForm)
