import React from 'react';
import PropTypes from 'prop-types';

const SlideArrow = ({color = '#ffffff'}) => {
  return (
    <svg width="26px" height="18px" viewBox="0 0 26 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Desktop-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="01-03-Vores-hold-02a-CJA" transform="translate(-1234.000000, -1151.000000)" fill={color} fillRule="nonzero">
          <g id="Vores-hold" transform="translate(0.000000, 880.000000)">
            <g id="Arrows" transform="translate(1223.000000, 256.000000)">
              <g id="Backwards-arrow" transform="translate(24.000000, 24.000000) scale(-1, 1) rotate(180.000000) translate(-24.000000, -24.000000) ">
                <g id="right-arrow" transform="translate(24.000000, 24.000000) rotate(180.000000) translate(-24.000000, -24.000000) translate(11.000000, 15.000000)">
                  <path d="M17.5080978,0.270707253 C17.1538901,-0.0902357511 16.5643696,-0.0902357511 16.1977771,0.270707253 C15.8435694,0.619456237 15.8435694,1.19989161 16.1977771,1.54782766 L22.8418546,8.08951314 L0.917307082,8.08951314 C0.406224198,8.09032608 3.63797881e-11,8.49028995 3.63797881e-11,8.99349652 C3.63797881e-11,9.4967031 0.406224198,9.90967392 0.917307082,9.90967392 L22.8418546,9.90967392 L16.1977771,16.4391654 C15.8435694,16.8001084 15.8435694,17.3813567 16.1977771,17.7292927 C16.5643696,18.0902358 17.1547158,18.0902358 17.5080978,17.7292927 L25.7250556,9.63896667 C26.0916481,9.29021769 26.0916481,8.70978231 25.7250556,8.36184627 L17.5080978,0.270707253 Z" id="Path"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

SlideArrow.propTypes = {
  color: PropTypes.string
}

export default SlideArrow;
