import React from 'react';
import PropTypes from 'prop-types';

const ImageColumns = ({section}) => {
  return (
    <div className={'sections__section__imageColumns md:mt-32 mt-16 grid md:grid-cols-3 grid-cols-1 gap-32'}
      data-aos="fade-up" data-aos-once="true" data-aos-offset="10"
    >
      {section.imageLeft.srcSet ? <img
        className="image-left"
        srcSet={section.imageLeft.srcSet}
        alt={section.imageLeft.altText} /> : null}
      {section.imageRight.srcSet ? <img
        className="image-right md:col-span-2"
        srcSet={section.imageRight.srcSet}
        alt={section.imageRight.altText} /> : null}
    </div>
  );
}

ImageColumns.propTypes = {
  section: PropTypes.any,
};

export default ImageColumns;

