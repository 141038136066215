import React from 'react';
import {useQuery} from '@apollo/client';
import {GET_PAGE, GET_CONTACT_PAGE, GET_ABOUT_PAGE, GET_EMPLOYEES, GET_NEWS_PAGE, GET_CAREERS_PAGE} from '../../gql';
import PropTypes from 'prop-types';
import {Error, AppShell} from '../components';

const Page = ({page, children}) => {
  let query = GET_PAGE;
  const options = {variables: {stringId: page.id}}

  switch (page.title) {
    case 'Kontakt':
    case 'Contact':
      query = GET_CONTACT_PAGE
      break
    case 'Om os':
    case 'About':
      query = GET_ABOUT_PAGE
      break
    case 'Vores team':
      query = GET_EMPLOYEES
      break
    case 'Videnscenter':
      query = GET_NEWS_PAGE
      options.variables.first = 3
      break
    case 'Karriere':
      query = GET_CAREERS_PAGE
      break
    default:
      query = GET_PAGE
  }

  const {data, loading, error} = useQuery(query, options);

  if (loading) {
    return <AppShell hidenav={true} loading={true} />
  }

  if (error || !data) {
    return <Error />
  }

  return children(data);

};

Page.propTypes = {
  page: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
}

export default Page;
